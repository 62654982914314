/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
  
  --red:#8B0124;
  --blue:#327E8A;
  --yellow: #EDC168;
  --white: rgba(248,248,248,1);
  --white-semi-trans: rgba(248,248,248,1);
  --white-pretty: #fff;
  --greyish: #e8e8e8;
  --grey-dark: rgba(10,10,10,1);

  --nav-size: 7vh;
  --border-radius-circle: 50%;
  --border-radius-curved: 10%;

  /* FONTS */
  --base-font: 'Courier New', Courier, monospace;

  /* Acier BAT Text Gris */
    /* font-family */
    --AcierBatTextGrisFF: acier-bat-gris, sans-serif;
    /* font-weight */
    --AcierBatTextGrisFW: 400;
    /* font-style */
    --AcierBatTextGrisFS: normal;


  /* Acier BAT Text Noir */
    /* font-family */
    --AcierBatTextNoirFF: acier-bat-noir, sans-serif;
    /* font-weight */
    --AcierBatTextNoirFW: 400;
    /* font-style */
    --AcierBatTextNoirFS: normal;

  /* Acier BAT Text Outline */
    /* font-family */
    --AcierBatTextOutlineFF: acier-bat-outline, sans-serif;
    /* font-weight */
    --AcierBatTextOutlineFW: 400;
    /* font-style */
    --AcierBatTextOutlineFS: normal;

  /* Acier BAT Text Solid */
    /* font-family */
    --AcierBatTextSolidFF: acier-bat-solid, sans-serif;
    /* font-weight */
    --AcierBatTextSolidFW: 400;
    /* font-style */
    --AcierBatTextSolidFS: normal;

  /* Acier BAT Text Strokes */
    /* font-family */
    --AcierBatTextStrokesFF: acier-bat-strokes, sans-serif;
    /* font-weight */
    --AcierBatTextStrokesFW: 400;
    /* font-style */
    --AcierBatTextStrokesFS: normal;
}

/* body {
  margin: 0;
  background: #151616;
  font-family: var(--AcierBatTextNoirFF);
  font-weight: var(--AcierBatTextNoirFW);
  font-size: var(--AcierBatTextNoirFS);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */



ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */
header {
  height: 7vh;
  width: 100vw;
  position: fixed;
  z-index: 111;
  top:0;
  left:0;
}

Link:hover {
  filter: brightness(1.2);
  text-shadow: -1px 1px 3px var(--grey-dark);
}

/* .navLinks {
  font-size: 1.7em;
  text-decoration: none;
  margin: 2.3%;
}

.navLinks:hover {
  filter: brightness(1.2);
  text-shadow: -1px 1px 3px #242526;
} */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: none;
  padding: 0 1rem;
  border-bottom: var(--border);
  width: 100vw;
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  
  /* justify-content: flex-end; */
  /* margin-left: 5%; */
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3%;
  /* padding: */
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  color: var(--blue);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  transition: filter 300ms;
}

.icon-button { 
  margin-right: 5%;
}

.icon-button:hover {
  filter: brightness(1.2);
  text-shadow: -1px 1px 3px #242526;
}


.icon-button svg { 
}


/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(29%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding-bottom: 3em;
  height: 120%;
  overflow: hidden;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
  height: fit-content;
}

.menu-item {
  height: 2em;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  font-size: 1.4em;
}


.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-left {
  margin-right: auto;
}

.icon-right {
  margin-left: auto;
}

.whiteIcon {
  color: white;
  fill: white;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}


#navLizKane {
  font-family: var(--AcierBatTextNoirFF);
  font-weight: var(--AcierBatTextNoirFW);
  font-style: var(--AcierBatTextNoirFS);

}

#navResume {
  font-family: var(--AcierBatTextNoirFF);
  font-weight: var(--AcierBatTextNoirFW);
  font-style: var(--AcierBatTextNoirFS);

}