@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://use.typekit.net/cye7zmh.css);
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
  
  --red:#8B0124;
  --blue:#327E8A;
  --yellow: #EDC168;
  --white: rgba(248,248,248,1);
  --white-semi-trans: rgba(248,248,248,1);
  --white-pretty: #fff;
  --greyish: #e8e8e8;
  --grey-dark: rgba(10,10,10,1);

  --nav-size: 7vh;
  --border-radius-circle: 50%;
  --border-radius-curved: 10%;

  /* FONTS */
  --base-font: 'Courier New', Courier, monospace;

  /* Acier BAT Text Gris */
    /* font-family */
    --AcierBatTextGrisFF: acier-bat-gris, sans-serif;
    /* font-weight */
    --AcierBatTextGrisFW: 400;
    /* font-style */
    --AcierBatTextGrisFS: normal;


  /* Acier BAT Text Noir */
    /* font-family */
    --AcierBatTextNoirFF: acier-bat-noir, sans-serif;
    /* font-weight */
    --AcierBatTextNoirFW: 400;
    /* font-style */
    --AcierBatTextNoirFS: normal;

  /* Acier BAT Text Outline */
    /* font-family */
    --AcierBatTextOutlineFF: acier-bat-outline, sans-serif;
    /* font-weight */
    --AcierBatTextOutlineFW: 400;
    /* font-style */
    --AcierBatTextOutlineFS: normal;

  /* Acier BAT Text Solid */
    /* font-family */
    --AcierBatTextSolidFF: acier-bat-solid, sans-serif;
    /* font-weight */
    --AcierBatTextSolidFW: 400;
    /* font-style */
    --AcierBatTextSolidFS: normal;

  /* Acier BAT Text Strokes */
    /* font-family */
    --AcierBatTextStrokesFF: acier-bat-strokes, sans-serif;
    /* font-weight */
    --AcierBatTextStrokesFW: 400;
    /* font-style */
    --AcierBatTextStrokesFS: normal;
}

/* body {
  margin: 0;
  background: #151616;
  font-family: var(--AcierBatTextNoirFF);
  font-weight: var(--AcierBatTextNoirFW);
  font-size: var(--AcierBatTextNoirFS);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */



ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #dadce1;
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */
header {
  height: 7vh;
  width: 100vw;
  position: fixed;
  z-index: 111;
  top:0;
  left:0;
}

Link:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  text-shadow: -1px 1px 3px rgba(10,10,10,1);
  text-shadow: -1px 1px 3px var(--grey-dark);
}

/* .navLinks {
  font-size: 1.7em;
  text-decoration: none;
  margin: 2.3%;
}

.navLinks:hover {
  filter: brightness(1.2);
  text-shadow: -1px 1px 3px #242526;
} */

/* <nav> */
.navbar {
  height: 7vh;
  height: var(--nav-size);
  background-color: none;
  padding: 0 1rem;
  border-bottom: 1px solid #474a4d;
  border-bottom: var(--border);
  width: 100vw;
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  
  /* justify-content: flex-end; */
  /* margin-left: 5%; */
}

/* <li> */
.nav-item {
  width: calc(7vh * 0.8);
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3%;
  /* padding: */
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  color: #327E8A;
  color: var(--blue);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-button { 
  margin-right: 5%;
}

.icon-button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  text-shadow: -1px 1px 3px #242526;
}


.icon-button svg { 
}


/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(29%);
  background-color: #242526;
  background-color: var(--bg);
  border: 1px solid #474a4d;
  border: var(--border);
  border-radius: 8px;
  border-radius: var(--border-radius);
  padding-bottom: 3em;
  height: 120%;
  overflow: hidden;
  transition: height 500ms ease;
  transition: height var(--speed) ease;
}

.menu {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.menu-item {
  height: 2em;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border-radius: var(--border-radius);
  transition: background 500ms;
  transition: background var(--speed);
  font-size: 1.4em;
}


.menu-item .icon-button:hover {
  -webkit-filter: none;
          filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-left {
  margin-right: auto;
}

.icon-right {
  margin-left: auto;
}

.whiteIcon {
  color: white;
  fill: white;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}


.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
  transition: all var(--speed) ease;
}


#navLizKane {
  font-family: acier-bat-noir, sans-serif;
  font-family: var(--AcierBatTextNoirFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextNoirFW);
  font-style: normal;
  font-style: var(--AcierBatTextNoirFS);

}

#navResume {
  font-family: acier-bat-noir, sans-serif;
  font-family: var(--AcierBatTextNoirFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextNoirFW);
  font-style: normal;
  font-style: var(--AcierBatTextNoirFS);

}
:root {
  --red:#8B0124;
  --blue:#327E8A;
  --yellow: #EDC168;
  --yellowDark: rgba(124,85,32,1);
  --white: rgba(248,248,248,1);
  --white-semi-trans: rgba(248,248,248,1);
  --white-semi-grey: rgba(248,248,248,.8);
  --white-pretty: #fff;
  --greyish: #e8e8e8;
  --greyish-semi-trans: rgba(201,200,201,.5);
  --greyish-solid: #737373;
  --grey-very-dark: rgba(25,25,25,1);
  --grey-very-dark2: rgba(34,34,34,1);
  --grey-dark: rgba(41,41,41,1);
  --grey-dark2: rgba(45,45,45,1);
  --grey-dark-light: rgba(56,56,56,1);
  --grey-dark-light2: rgb(32, 30, 32);
  --grey-dark-light3: rgb(46, 46, 46);
  --grey-dark-light4: rgb(80, 80, 80);
  --mintGreen: #89FAD0;
  --speed: 500ms;
  --nav-size: 7vh;
  --border-radius-circle: 50%;
  --border-radius-curved: 10%;
  --size: 60vmin;
  --space: 8vmin;
  --duration: 450ms;
  --ease-out: cubic-bezier(0.25, 1, 0.5, 1);
  --bounce-out: cubic-bezier(0.34, 1.56, 0.64, 1);
  /* FONTS */
  --base-font: 'Courier New', Courier, monospace;
  --Lobster: 'Lobster';
  --Chic: 'Bodoni Moda', serif;
  /* Acier BAT Text Gris */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextGrisFF: acier-bat-gris, sans-serif;
  --AcierBatTextGrisFS: normal;
  --AcierBatTextGrisFW: 400;
  /* Acier BAT Text Noir */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextNoirFF: acier-bat-noir, sans-serif;
  --AcierBatTextNoirFW: 400;
  --AcierBatTextNoirFS: normal;
  /* Acier BAT Text Outline */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextOutlineFF: acier-bat-outline, sans-serif;
  --AcierBatTextOutlineFW: 600;
  --AcierBatTextOutlineFS: normal;
  /* Acier BAT Text Solid */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextSolidFF: acier-bat-solid, sans-serif;
  --AcierBatTextSolidFW: 400;
  --AcierBatTextSolidFS: normal;
  /* Acier BAT Text Strokes */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextStrokesFF: acier-bat-strokes, sans-serif;
  --AcierBatTextStrokesFW: 600;
  --AcierBatTextStrokesFS: normal;
}

.redText {
  color: #8B0124;
  color: var(--red);
}

.blueText {
  color: #327E8A;
  color: var(--blue);
}

.yellowText {
  color: #EDC168;
  color: var(--yellow);
}

.box-shadow {
  box-shadow: 0 0 0.8em #191919;
}

.text-shadow {
  text-shadow: 0px 0px 0.1em #191919;
}

.grey-dark {
  background-color: #292929;
}

.grey-dark-light {
  background-color: #383838;
}

.grey-very-dark2 {
  background-color: #222222;
}

.grey-dark2 {
  background-color: #2d2d2d;
}

.grey-very-dark {
  background-color: #191919;
}

/* --------------------------------------- */
/* ------------ CONTACT PAGE ------------- */
/* ----------- & REF todo.css ------------ */
/* --------------------------------------- */
#contactPage {
  /* z-index: 17; */
  font-family: 'Bodoni Moda', serif;
  margin: 0vh 0 0vh 0;
  min-width: 100vw;
  min-height: 75vh;
  max-height: 105vh;
  z-index: 10;
  padding: 12vh 0 3vh 0;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgba(25,25,25,1), rgba(41,41,41,1));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
}

#contactPageWrapper {
  min-height: 80vh;
  max-height: 100vh;
  overflow: hidden;
}

.contactRow {
  width: 100%;
  min-height: 75vh;
  max-height: 75vh;
  margin: 3vh 0 11vh 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr;
      grid-template-areas: "email socialMedia";
}

#cardFormEmail {
  max-height: 79vh;
}
::-webkit-input-placeholder {
color: rgba(25,25,25,1);
color: var(--grey-very-dark);
opacity: 1;
}
::-moz-placeholder {
color: rgba(25,25,25,1);
color: var(--grey-very-dark);
opacity: 1;
}
:-ms-input-placeholder {
color: rgba(25,25,25,1);
color: var(--grey-very-dark);
opacity: 1;
}
:-moz-placeholder { 
color: rgba(25,25,25,1); 
color: var(--grey-very-dark);
opacity: 1;
}

.formRow {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: baseline;
  width: 100%;
}

.formRow #formName {
  flex: 1 1;
}

.formRow #formEmail {
  flex: 1 1;
  align-self: baseline;
}

.formRow #formCompany #formSubject {
  flex: 2 1;
}

.contactRow {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 100%;
}

.card-body > form {
  width: 100%;
}

#emailForm {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: email;
}

#message {
  resize: none;
  overflow: scroll;
}

.cardTitle {
  font-family: 'Lobster';
  font-family: var(--Lobster);
  font-size: 3em;
  color: #327E8A;
  color: var(--blue);
  margin: -10vh 0 -.5vh 0;
}

hr {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.formGroup > input, .formGroup > select, .formGroup > textarea {
  /* background-color: var(--white-pretty); */
  /* background-color: rgba(255, 255, 255, 0.8); */
  color: rgba(25,25,25,1);
  
}

input:focus, textarea:focus, select:focus {
  outline: rgba(41,41,41,1);
  outline: var(--grey-dark);
  border: 2px solid rgba(41,41,41,1);
  border: 2px solid var(--grey-dark);
}

#subjectPlaceholder {
  text-align: center;
}

#socialMedia {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: socialMedia;
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  text-decoration: none;
}

.formLabel {
  /* color: var(--white-pretty); */
  color: rgba(248,248,248,1);
  color: var(--white-semi-trans);
  /* color: var(--blue); */
  font-size: 1.3em;
  font-weight: 800;
}

#upload-file{
  cursor: pointer;
}

#submitShow {
  margin-top: .5em;
  font-size: 1em;
}

.socItem > a {
  text-decoration: none;
}

.social {
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: space-around;
}

.social > i {
  flex: 1 1;
  align-self: flex-start;
  font-size: 3em;
  margin: 0 2% 0 5%;
  -ms-grid-row-align: left;
  align-self: left;
  text-align: left;
  color: #EDC168;
  color: var(--yellow);
}

.social > p {
  flex: 2 1;
  align-self: flex-end;
  font-size: .6em;
  -ms-grid-row-align: left;
  align-self: left;
  text-align: left;
  text-decoration: none;
  color: #EDC168;
  color: var(--yellow);
}

.social:hover > p {
  color: #327E8A;
  color: var(--blue);
}

.social:hover > i {
  color: #327E8A;
  color: var(--blue);
}

#socialMedia > div:hover {
  /* background-color: var(--grey-dark2); */
  background-color: rgba(45,45,45,1);
  background-color: var(--grey-dark2);
  -webkit-filter: background brightness(2);
  filter: background brightness(2);
  transition: all 150ms ease-in-out;
  /* -webkit-transition-delay: 900ms;
  transition-delay: 900ms; */
  border-radius: 15px;
}

#linkedIn {
  flex: 1 1;
}

#github {
  flex: 2 1;
}

#twitter {
  flex: 3 1;
}

#phone {
  flex: 4 1;
}

#email {
  flex: 5 1;
}

#location {
  flex: 6 1;
}

#footer {
  /* background-color: var(--grey-dark-light); */
  background-color: rgba(45,45,45,1);
  background-color: var(--grey-dark2);
  opacity: .8;
  width: 100vw;
  display: flex;
  height: 7vh;
  /* bottom: 0; */
  position: relative;
  bottom: 0;
  display: -ms-flexbox;
  justify-content: center;
  align-content: center;
}

footer > a {
  position: relative;
  display: flex;
  max-width: 8vw;
  margin: .4% 1vw;
  height: 70%;
}

footer > a img {
  /* width: ; */
  width: auto;
  height: 100%;
  transition: scale 350ms ease-in-out;
}

footer > a img:hover {
  transform: scale(1.2);
  transition: scale 350ms ease-in-out;
}

#LizKaneFooter {
  margin: 0 2vw;
  /* color: var(--red); */
  color: #8B0124;
  color: var(--red);

  font-family: acier-bat-solid, sans-serif;

  font-family: var(--AcierBatTextSolidFF);
  /* font-family: var(--AcierBatTextOutlineFF); */
  font-weight: 600;
  font-weight: var(--AcierBatTextOutlineFW);
  font-style: normal;
  font-style: var(--AcierBatTextOutlineFS);
  font-size: 2.5em;
}
/*# sourceMappingURL=contactPage.css.map */
:root {
  --red:#8B0124;
  --blue:#327E8A;
  --yellow: #EDC168;
  --yellowDark: rgba(124,85,32,1);
  --white: rgba(248,248,248,1);
  --white-semi-trans: rgba(248,248,248,1);
  --white-semi-grey: rgba(248,248,248,.8);
  --white-pretty: #fff;
  --greyish: #e8e8e8;
  --greyish-semi-trans: rgba(201,200,201,.5);
  --greyish-solid: #737373;
  --grey-very-dark: rgba(25,25,25,1);
  --grey-very-dark2: rgba(34,34,34,1);
  --grey-dark: rgba(41,41,41,1);
  --grey-dark2: rgba(45,45,45,1);
  --grey-dark-light: rgba(56,56,56,1);
  --grey-dark-light2: rgb(32, 30, 32);
  --grey-dark-light3: rgb(46, 46, 46);
  --grey-dark-light4: rgb(80, 80, 80);
  --mintGreen: #89FAD0;
  --speed: 500ms;
  --nav-size: 7vh;
  --border-radius-circle: 50%;
  --border-radius-curved: 10%;
  --size: 60vmin;
  --space: 8vmin;
  --duration: 450ms;
  --ease-out: cubic-bezier(0.25, 1, 0.5, 1);
  --bounce-out: cubic-bezier(0.34, 1.56, 0.64, 1);
  /* FONTS */
  --base-font: 'Courier New', Courier, monospace;
  --Lobster: 'Lobster';
  --Chic: 'Bodoni Moda', serif;
  /* Acier BAT Text Gris */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextGrisFF: acier-bat-gris, sans-serif;
  --AcierBatTextGrisFS: normal;
  --AcierBatTextGrisFW: 400;
  /* Acier BAT Text Noir */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextNoirFF: acier-bat-noir, sans-serif;
  --AcierBatTextNoirFW: 400;
  --AcierBatTextNoirFS: normal;
  /* Acier BAT Text Outline */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextOutlineFF: acier-bat-outline, sans-serif;
  --AcierBatTextOutlineFW: 600;
  --AcierBatTextOutlineFS: normal;
  /* Acier BAT Text Solid */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextSolidFF: acier-bat-solid, sans-serif;
  --AcierBatTextSolidFW: 400;
  --AcierBatTextSolidFS: normal;
  /* Acier BAT Text Strokes */
  /* font-family */
  /* font-weight */
  /* font-style */
  --AcierBatTextStrokesFF: acier-bat-strokes, sans-serif;
  --AcierBatTextStrokesFW: 600;
  --AcierBatTextStrokesFS: normal;
}

.redText {
  color: #8B0124;
  color: var(--red);
}

.blueText {
  color: #327E8A;
  color: var(--blue);
}

.yellowText {
  color: #EDC168;
  color: var(--yellow);
}

.box-shadow {
  box-shadow: 0 0 0.8em #191919;
}

.text-shadow {
  text-shadow: 0px 0px 0.1em #191919;
}

.grey-dark {
  background-color: #292929;
}

.grey-dark-light {
  background-color: #383838;
}

.grey-very-dark2 {
  background-color: #222222;
}

.grey-dark2 {
  background-color: #2d2d2d;
}

.grey-very-dark {
  background-color: #191919;
}

section #titleSection {
  padding: 5vh 5vw;
  min-width: 90vw;
  max-width: 10vw;
  min-height: 80vh;
  margin: 20vh auto 0;
  -ms-grid-row-align: center;
  align-self: center;
  align-content: center;
}

/* #region Mask Text */
.titleContainer {
  position: relative;
  --maskX: 0;
  --maskY: 0;
  min-width: 100%;
  min-height: 100%;
}

.titleContainer h1, .titleContainer #intro {
  font-family: "Bodoni Moda", serif;
  font-size: 10em;
  line-height: 1.1em;
  text-align: center;
  width: 100%;
}

.titleContainer h3, .titleContainer #introSE {
  font-family: "Bodoni Moda", serif;
  font-size: 7em;
  margin: 0 auto;
  line-height: 1.1em;
  text-align: center;
  width: 100%;
}

.titleWrapper {
  color: #327E8A;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.cloneWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #65cddd;
  transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-clip-path: polygon(0 0, calc(var(--maskX) * 1% + (var(--maskY) - 50) * .4%) 0, calc(var(--maskX) * 1% + (var(--maskY) - 50) * -.4%) 100%, 0 100%);
          clip-path: polygon(0 0, calc(var(--maskX) * 1% + (var(--maskY) - 50) * .4%) 0, calc(var(--maskX) * 1% + (var(--maskY) - 50) * -.4%) 100%, 0 100%);
}

/* #endregion */
/* SEEK LOGO region */
div#seekLogoContainer {
  background-color: aliceblue;
  grid-area: seek;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  overflow: show;
  perspective: 40px;
}

#seekLogoContainer > img.seekLogo {
  position: absolute;
  max-width: 70vw;
  opacity: .8;
  background-blend-mode: hard-light;
}

#seekPhoto13 {
  top: 10;
  z-index: 13;
}

#seekPhoto15 {
  top: 5;
  z-index: 0;
}

#seekPhoto17 {
  top: -8.5vh;
  z-index: -2;
}

#seekPhoto19 {
  top: -18.5vh;
  z-index: -1;
}

#seekPhoto21 {
  top: 10;
  z-index: 21;
}

#seekPhoto23 {
  top: 10;
  z-index: 23;
}

/* end SEEK LOGO region */
/*# sourceMappingURL=window.css.map */


@charset "UTF-8";
/* @import "./_variables"; */

/* @import url("./ProjectStyle/project.css");*/ 

/* ============================= */
/* START ROOT VARIABLES SET UP */
/* ============================= */

* {
  box-sizing: border-box;
}
:root {

  --red:#8B0124;
  --red-light:#d60237;
  --red-dark:rgba(70,1,18,1);
  --red-very-dark:rgb(39, 2, 11);
  --blue:#327E8A;
  --blue-light:#65cddd;
  --blue-dark:#163a3f;
  --yellow: #EDC168;
  --yellowDark: rgba(124,85,32,1);

  --white: rgba(248,248,248,1);
  --white-semi-trans: rgba(248,248,248,.8);
  --white-semi-grey: rgba(248,248,248,.8);
  --white-pretty: #fff;

  --greyish: #e8e8e8;
  --greyish-semi-trans: rgba(201,200,201,.5);
  --greyish-solid: #737373;
  --greyish-light: rgba(124,124,124,1);
  /* --grey-dark: rgba(13,13,13,1); */
  --grey-very-dark: rgb(25,25,25);
  --grey-very-dark2: rgb(34,34,34);
  /* BODY COLOR = grey-dark */
  --grey-dark: rgb(41,41,41);
  --grey-dark2: rgb(45,45,45);
  /* // --grey-dark-light: rgba(23,23,23,1); */
  --grey-dark-light: rgb(56,56,56);
  --grey-dark-light2: rgb(32, 30, 32);
  --grey-dark-light3: rgb(46, 46, 46);
  --grey-dark-light4: rgb(80, 80, 80);
  /* background-color: rgba(var(--grey-dark-light), 1); */
  --speed: 500ms;
  --nav-size: 7vh;
  --border-radius-circle: 50%;
  --border-radius-curved: 10%;
  --border-radius-10: 10px;
  --border-radius-25: 1.6vw;
  --new-font-size: *1.5;
  --size: 60vmin;
  --space: 8vmin;
  --duration: 450ms;
  --ease-out: cubic-bezier(0.25, 1, 0.5, 1);
  --bounce-out: cubic-bezier(0.34, 1.56, 0.64, 1);

  /* FONTS */
  --base-font: 'Courier New', Courier, monospace;
  --Lobster: 'Lobster';
  --Chic: 'Bodoni Moda', serif;
  --Lora: 'Lora', serif;

  /* Acier BAT Text Gris *//* font-family *//* font-weight *//* font-style */
  --AcierBatTextGrisFF: 'acier-bat-gris', sans-serif;
  --AcierBatTextGrisFS: normal;
  --AcierBatTextGrisFW: 400;

  /* Acier BAT Text Noir *//* font-family *//* font-weight *//* font-style */
  --AcierBatTextNoirFF: 'acier-bat-noir', sans-serif;
  --AcierBatTextNoirFW: 400;
  --AcierBatTextNoirFS: normal;
  
  /* Acier BAT Text Outline *//* font-family *//* font-weight *//* font-style */
  --AcierBatTextOutlineFF: 'acier-bat-outline', sans-serif;
  --AcierBatTextOutlineFW: 400;
  --AcierBatTextOutlineFS: normal;
  
  /* Acier BAT Text Solid *//* font-family *//* font-weight *//* font-style */
  --AcierBatTextSolidFF: 'acier-bat-solid', sans-serif;
  --AcierBatTextSolidFW: 400;
  --AcierBatTextSolidFS: normal;
  
  /* Acier BAT Text Strokes *//* font-family *//* font-weight *//* font-style */
  --AcierBatTextStrokesFF: 'acier-bat-strokes', sans-serif;
  --AcierBatTextStrokesFW: 400;
  --AcierBatTextStrokesFS: normal;
}



/* RED TEXT VARIATIONS */
.redText {
color: #8B0124;
color: var(--red);
}
/* BLUE TEXT VARIATIONS */
.blueText {
color: #327E8A;
color: var(--blue);
}
.lightBlueText {
  color: #65cddd;
  color: var(--blue-light);
}
.darkBlueText {
  color: #163a3f;
  color: var(--blue-dark);
}
/* YELLOW TEXT VARIATIONS */
.yellowText {
color: #EDC168;
color: var(--yellow);
}


  
/*  GREY SCALE FOR ALL BACKGROUNDS & SHADOWS*/
  /* GREY TEXT VARIATIONS */
.greyedOutText {
  color: rgb(41,41,41);
  color: var(--grey-dark);
}

.box-shadow {
    box-shadow: 0 0 .8em rgb(25,25,25);
    box-shadow: 0 0 .8em var(--grey-very-dark);
}

.semi-trans-border {
  border: 1px solid rgba(34,34,34,.1);
  border-radius: 10%;
  border-radius: var(--border-radius-curved);
}

.textShadow {
  text-shadow: 
   0 0 0 rgb(25,25,25),
   -1px 1px 0 rgb(25,25,25),
   -2px 2px 0 rgb(25,25,25),
   -3px 3px 0 rgb(25,25,25);
  text-shadow: 
   0 0 0 var(--grey-very-dark),
   -1px 1px 0 var(--grey-very-dark),
   -2px 2px 0 var(--grey-very-dark),
   -3px 3px 0 var(--grey-very-dark);
   /* 4px 4px 0 var(--grey-very-dark) */
   /* 5px 5px 0 var(--grey-very-dark) */
   /* 6px 6px 0 var(--grey-very-dark) */
   /* 7px 7px 0 var(--grey-very-dark), */
   /* 8px 8px 0 var(--grey-very-dark) */
   
}

  /* background color */
.grey-dark {
    background-color: var(--grey-dar)k
}
.grey-dark-light {
    background-color: rgb(56,56,56);
    background-color: var(--grey-dark-light);
}
.grey-very-dark2 {
    background-color: rgb(34,34,34);
    background-color: var(--grey-very-dark2);
}
.grey-dark2 {
    background-color: rgb(45,45,45);
    background-color: var(--grey-dark2);
}
.grey-very-dark {
    background-color: rgb(25,25,25);
    background-color: var(--grey-very-dark);
}
.text3DHover:hover {
  cursor: pointer;
  text-shadow:
   1px 1px 0 rgb(25,25,25),
   1.5px 1.5px 0 rgb(25,25,25),
   1.75px 1.75px 0 rgb(25,25,25),
   2px 2px 0 rgb(25,25,25),
   2.25px 2.25px 0 rgb(25,25,25),
   2.5px 2.5px 0 rgb(25,25,25),
   2.75px 2.75px 0 rgb(25,25,25),
   3px 3px 0 rgb(25,25,25),
   3.25px 3.25px 0 rgb(25,25,25),
   3.5px 3.5px 0 rgb(25,25,25),
   3.75px 3.75px 0 rgb(25,25,25),
   4px 4px 0 rgb(25,25,25),
   4.25px 4.25px 0 rgb(25,25,25),
   4.5px 4.5px 0 rgb(25,25,25),
   4.75px 4.75px 0 rgb(25,25,25),
   5px 5px 0 rgb(25,25,25),
   5.25px 5.25px 0 rgb(25,25,25),
   5.5px 5.5px 0 rgb(25,25,25),
   5.75px 5.75px 0 rgb(25,25,25),
   6px 6px 0 rgb(25,25,25),
   6.25px 6.25px 0 rgb(25,25,25),
   6.5px 6.5px 0 rgb(25,25,25),
   6.75px 6.75px 0 rgb(25,25,25),
   7px 7px 0 rgb(25,25,25),
   7.25px 7.25px 0 rgb(25,25,25),
   7.5px 7.5px 0 rgb(25,25,25),
   7.75px 7.75px 0 rgb(25,25,25),
   8px 8px 0 rgb(25,25,25),
   8.25px 8.25px 0 rgb(25,25,25),
   8.5px 8.5px 0 rgb(25,25,25),
   8.75px 8.75px 0 rgb(25,25,25),
   9px 9px 0 rgb(25,25,25),
   9.25px 9.25px 0 rgb(25,25,25),
   9.5px 9.5px 0 rgb(25,25,25),
   9.75px 9.75px 0 rgb(25,25,25),
   10px 10px 0 rgb(25,25,25),
   10.25px 10.25px 0 rgb(25,25,25),
   10.5px 10.5px 0 rgb(25,25,25),
   10.75px 10.75px 0 rgb(25,25,25),
   11px 11px 0 rgb(25,25,25),
   11.25px 11.25px 0 rgb(25,25,25),
   11.5px 11.5px 0 rgb(25,25,25),
   11.75px 11.75px 0 rgb(25,25,25),
   12px 12px 0 rgb(25,25,25),
   12.25px 12.25px 0 rgb(25,25,25),
   12.5px 12.5px 0 rgb(25,25,25),
   12.75px 12.75px 0 rgb(25,25,25),
   13px 13px 0 rgb(25,25,25),
   13.25px 13.25px 0 rgb(25,25,25),
   13.5px 13.5px 0 rgb(25,25,25),
   13.75px 13.75px 0 rgb(25,25,25),
   14px 14px 0 rgb(25,25,25),
   14.25px 14.25px 0 rgb(25,25,25),
   14.5px 14.5px 0 rgb(25,25,25),
   14.75px 14.75px 0 rgb(25,25,25),
   15px 15px 0 rgb(25,25,25),
   15.25px 15.25px 0 rgb(25,25,25),
   15.5px 15.5px 0 rgb(25,25,25),
   15.75px 15.75px 0 rgb(25,25,25),
   16px 16px 0 rgb(25,25,25),
   16.25px 16.25px 0 rgb(25,25,25),
   16.5px 16.5px 0 rgb(25,25,25),
   16.75px 16.75px 0 rgb(25,25,25),
   17px 17px 0 rgb(25,25,25),
   17.25px 17.25px 0 rgb(25,25,25),
   17.5px 17.5px 0 rgb(25,25,25),
   17.75px 17.75px 0 rgb(25,25,25),
   18px 18px 0 rgb(25,25,25),
   18.25px 18.25px 0 rgb(25,25,25),
   18.5px 18.5px 0 rgb(25,25,25),
   18.75px 18.75px 0 rgb(25,25,25),
   19px 19px 0 rgb(25,25,25);
  text-shadow:
   1px 1px 0 var(--grey-very-dark),
   1.5px 1.5px 0 var(--grey-very-dark),
   1.75px 1.75px 0 var(--grey-very-dark),
   2px 2px 0 var(--grey-very-dark),
   2.25px 2.25px 0 var(--grey-very-dark),
   2.5px 2.5px 0 var(--grey-very-dark),
   2.75px 2.75px 0 var(--grey-very-dark),
   3px 3px 0 var(--grey-very-dark),
   3.25px 3.25px 0 var(--grey-very-dark),
   3.5px 3.5px 0 var(--grey-very-dark),
   3.75px 3.75px 0 var(--grey-very-dark),
   4px 4px 0 var(--grey-very-dark),
   4.25px 4.25px 0 var(--grey-very-dark),
   4.5px 4.5px 0 var(--grey-very-dark),
   4.75px 4.75px 0 var(--grey-very-dark),
   5px 5px 0 var(--grey-very-dark),
   5.25px 5.25px 0 var(--grey-very-dark),
   5.5px 5.5px 0 var(--grey-very-dark),
   5.75px 5.75px 0 var(--grey-very-dark),
   6px 6px 0 var(--grey-very-dark),
   6.25px 6.25px 0 var(--grey-very-dark),
   6.5px 6.5px 0 var(--grey-very-dark),
   6.75px 6.75px 0 var(--grey-very-dark),
   7px 7px 0 var(--grey-very-dark),
   7.25px 7.25px 0 var(--grey-very-dark),
   7.5px 7.5px 0 var(--grey-very-dark),
   7.75px 7.75px 0 var(--grey-very-dark),
   8px 8px 0 var(--grey-very-dark),
   8.25px 8.25px 0 var(--grey-very-dark),
   8.5px 8.5px 0 var(--grey-very-dark),
   8.75px 8.75px 0 var(--grey-very-dark),
   9px 9px 0 var(--grey-very-dark),
   9.25px 9.25px 0 var(--grey-very-dark),
   9.5px 9.5px 0 var(--grey-very-dark),
   9.75px 9.75px 0 var(--grey-very-dark),
   10px 10px 0 var(--grey-very-dark),
   10.25px 10.25px 0 var(--grey-very-dark),
   10.5px 10.5px 0 var(--grey-very-dark),
   10.75px 10.75px 0 var(--grey-very-dark),
   11px 11px 0 var(--grey-very-dark),
   11.25px 11.25px 0 var(--grey-very-dark),
   11.5px 11.5px 0 var(--grey-very-dark),
   11.75px 11.75px 0 var(--grey-very-dark),
   12px 12px 0 var(--grey-very-dark),
   12.25px 12.25px 0 var(--grey-very-dark),
   12.5px 12.5px 0 var(--grey-very-dark),
   12.75px 12.75px 0 var(--grey-very-dark),
   13px 13px 0 var(--grey-very-dark),
   13.25px 13.25px 0 var(--grey-very-dark),
   13.5px 13.5px 0 var(--grey-very-dark),
   13.75px 13.75px 0 var(--grey-very-dark),
   14px 14px 0 var(--grey-very-dark),
   14.25px 14.25px 0 var(--grey-very-dark),
   14.5px 14.5px 0 var(--grey-very-dark),
   14.75px 14.75px 0 var(--grey-very-dark),
   15px 15px 0 var(--grey-very-dark),
   15.25px 15.25px 0 var(--grey-very-dark),
   15.5px 15.5px 0 var(--grey-very-dark),
   15.75px 15.75px 0 var(--grey-very-dark),
   16px 16px 0 var(--grey-very-dark),
   16.25px 16.25px 0 var(--grey-very-dark),
   16.5px 16.5px 0 var(--grey-very-dark),
   16.75px 16.75px 0 var(--grey-very-dark),
   17px 17px 0 var(--grey-very-dark),
   17.25px 17.25px 0 var(--grey-very-dark),
   17.5px 17.5px 0 var(--grey-very-dark),
   17.75px 17.75px 0 var(--grey-very-dark),
   18px 18px 0 var(--grey-very-dark),
   18.25px 18.25px 0 var(--grey-very-dark),
   18.5px 18.5px 0 var(--grey-very-dark),
   18.75px 18.75px 0 var(--grey-very-dark),
   19px 19px 0 var(--grey-very-dark);
  transform:scale(1.4) ;
  transition: all 350ms ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  background: rgb(41,41,41);
  background: var(--grey-dark);
  /* background: var(--grey-very-dark); */
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 1em;
  color: #fff;
  color: var(--white-pretty);
  top: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}


/* ============================= */
/* END ROOT VARIABLES SET UP */
/* ============================= */

/* ============================= */
/* START SASS IMPORTS */
/* ============================= */

#textCarouselContainer {
  position: relative;
  width: 100vw;
  height: 75vmin;
  background-size: cover;
  /* font-family: 'Lobster'; */
  /* font-family: var(--Chic); */
  font-family: 'Lora', serif;
  font-family: var(--Lora);
  margin: -13.25vh auto 0vw;
  padding: 0vw 0 7vh 0;
  display: fixed;
  justify-content: center;
  align-content: center;
  align-content: center;
  background-color: rgb(25,25,25);
  background-color: var(--grey-very-dark);
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
}

#carousel {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  min-width: 90vw;
  margin: 0 auto;
  right: 5%;
}

.textCarouselItem {
  display:flex;
  justify-content: center;
  align-content: center;
  transition: all 150ms ease-in-out;
}


.textCarouselItem > div {
  min-height: 40vh;
  min-width: 15vw;
  max-width: 15vw;
  align-items: center;
  text-align: bottom;
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: 'Lora', serif;
  font-family: var(--Lora);
  /* background-color: rgba(248,248,248,.2); */
  background-color: rgba(56,56,56,1);
  color: rgb(25,25,25);
  color: var(--grey-very-dark);
  /* text-shadow: 0 0 1em rgba(124,124,124,.5); */
  font-weight: 400;
}



.arrow {
  position: absolute;
  width: 4.875vw;
  height: 4.875vw;
  /* background-color: none; */
  background-color: rgba(248,248,248,.1);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.8vw;
  line-height: 1vw;
  margin-top: 1vw;
  z-index: 100;
  color: rgb(39, 2, 11);
  color: var(--red-very-dark);
  transition: all 300ms ease;
}

.arrow-right {
  right: -4vw;
  box-shadow: .05em .05em .08em rgb(56,56,56);
  box-shadow: .05em .05em .08em var(--grey-dark-light);
  text-shadow: .05em .05em .08em rgb(56,56,56);
  text-shadow: .05em .05em .08em var(--grey-dark-light);
}

.arrow-left {
  left: -4vw;
}
.arrow-left>i, .arrow-right>i  {
  /* color: var(--white-pretty); */
  /* color: var(--grey-very-dark2); */
  font-size: 3.5vw;
  text-shadow: .05em .05em .2em rgb(25,25,25);
  text-shadow: .05em .05em .2em var(--grey-very-dark);
}


.arrow:hover {
  transform: scale(1.2);
  transition: all 300ms ease-in-out;
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
  box-shadow: .05em .05em .08em rgb(25,25,25);
  box-shadow: .05em .05em .08em var(--grey-very-dark);
  color: #d60237;
  color: var(--red-light);
}

.item {
  border-radius: 1.7vw;
  position: relative;
  text-align: center;
  align-content: center;
  color: #fff;
  color: var(--white-pretty);
  position: absolute;
  min-width: 15vw;
  min-height: 80%;
  margin: auto auto;
  align-self: center;
  transition: all 400ms ease-in-out;
}

.item:hover {
  transform: scale(1.04);
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  -webkit-transition: all 400ms ease-in-out;
  box-shadow: 0 0 1em rgb(25,25,25);
  box-shadow: 0 0 1em var(--grey-very-dark);
  font-weight: 700;
  font-size: 1.42vw;
  transition: all 400ms ease-in-out;
  /* color: var(--red-light); */
  /* color: var(--red); */
  /* text-shadow: 
  1px 0px 0em var(--grey-very-dark),
  1px 0px 1px var(--grey-very-dark),
  1px 0px 2px var(--grey-very-dark),
  0px 0px 4px var(--white-semi-trans); */
}


.textCarouselItem > div > p {
  font-size: 1.4vw;
  padding: 1vw;
}

#quotesLeft, #quotesRight{
  position: absolute;
  font-size: 5vw;
  z-index: 50;
  opacity: 1;
  /* color: var(--grey-dark); */
  color: rgb(39, 2, 11);
  color: var(--red-very-dark);
    /* color: var(--grey-very-dark); */
}
#quotesLeft{ 
  margin-right: 68%;
}
#quotesRight{
  margin-left: 68%;
}

.item:hover #quotesLeft, .item:hover #quotesRight {
  /* color: var(--blue); */
  -webkit-filter: brightness(1.6);
          filter: brightness(1.6);
}

.level-2 {
  height: 40vh;
  min-width: 15vw;
  max-width: 15vw;
  /* background: rgba(210,210,210,.3); */
  left: 72.5vw;
  margin-top: 1vw;
}
.level-2>#quotesLeft{
  margin-bottom: 124%;
}
.level-2>#quotesRight {
  margin-top: 123.5%;
} 

.level-1 {
  height: 45vh;
  min-width: 16vw;
  max-width: 16vw;
  /* background: rgba(210,210,210,.3); */
  left: 55vw;
}
.level-1>#quotesLeft{
  margin-bottom: 141.5%;
}
.level-1>#quotesRight {
  margin-top: 141.5%;
} 


.level0 {
  height: 48vh;
  min-width: 18vw;
  max-width: 18vw;
  /* background: rgba(210,210,210,.3); */
  left: 37.5vw;
}
.level0>#quotesLeft{
  margin-bottom: 152.5%;
}
.level0>#quotesRight {
  margin-top: 151.5%;
} 

.level1 {
  height: 45vh;
  max-width: 16vw;
  min-width: 16vw;
  /* background: rgba(210,210,210,.3); */
  left: 20vw;
}
.level1>#quotesLeft{
  margin-bottom: 141.5%;
}
.level1>#quotesRight {
  margin-top: 141.5%;
} 


.level2 {
  height: 40vh;
  min-width: 15vw;
  max-width: 15vw;
  /* background: rgba(210,210,210,.3); */
  left: 2.5vw;
}
.level2>#quotesLeft{
  margin-bottom: 124%;
}
.level2>#quotesRight {
  margin-top: 124%;
} 

/* .level-2:hover, .level2:hover{
  color: var(--blue);
  text-shadow: 1px 0px 0px var(--blue-dark);
  text-shadow:
  1px 0px 0px var(--red-very-dark),
  2px 1px 0px var(--red-very-dark),
  3px 2px 0px var(--red-very-dark),
  4px 3px 0px var(--red-very-dark);
}
.level-1:hover, .level1:hover {
  color: var(--blue);
  text-shadow: 1px 0px 0px var(--blue-dark);
  text-shadow:
  1px 0px 0px var(--red-very-dark),
  2px 1px 0px var(--red-very-dark),
  3px 2px 0px var(--red-very-dark),
  4px 3px 0px var(--red-very-dark);
}
.level0:hover {
  color: var(--blue);
  text-shadow: 1px 0px 0px var(--blue-dark);
  text-shadow:
  1px 0px 0px var(--red-very-dark),
  2px 1px 0px var(--red-very-dark),
  3px 2px 0px var(--red-very-dark),
  4px 3px 0px var(--red-very-dark);
} */

  .left-enter {
  opacity: 0;
  /* left: -60px; */
  left: -8vw;
  height: 15vw;
  width: 7.2vw;
  line-height: 15vw;
  margin-top: 3.2vw;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: 4vw;
  height: 12vw;
  width: 8.8vw;
  line-height: 2vw;
  margin-top: 1.6vw;
  transition: left 1.5s, opacity 1.2s, height 1.2s, width 1.5s, margin-top 1.2s, line-height 1.2s;
}

.left-leave {
  opacity: 1;
  left: 81.25vw;
  height: 12vw;
  width: 8.8vw;
  /* line-height: 150px; */
  margin-top: 1.6vw;
}

.left-leave.left-leave-active {
  left: 780px;
  opacity: 0;
  height: 9.6vw;
  line-height: 9.6vw;
  margin-top: 3.2vw;
  width: 7.2vw;
  transition: left 1.5s, opacity 1.5s, height 1.5s, width 1.5s, margin-top 1.5s, line-height 1.5s;
}

.right-enter {
  opacity: 0;
  left: 60.8vw;
  height: 9.6vw;
  width: 7.2vw;
  line-height: 9.6vw;
  margin-top: 3.2vw;
}

.right-enter.right-enter-active {
  left: 52vw;
  opacity: 1;
  height: 12vw;
  margin-top: 1.6vw;
  line-height: 12vw;
  width: 8.8vw;
  transition: left 1.5s, opacity 1.5s, height 1.5s, width 1.5s, margin-top 1.5s, line-height 1.5s;
}

.right-leave {
  left: 4vw;
  height: 12vw;
  opacity: 1;
  margin-top: 1.6vw;
  line-height: 12vw;
  width: 8.8vw;
}

.right-leave.right-leave-active {
  left: -4.8vw;
  opacity: 0;
  height: 9.6;
  width: 7.2vw;
  line-height: 9.6vw;
  margin-top: 3.2vw;
  transition: left 1.5s, opacity 1.5s, height 1.5s, width 1.5s, margin-top 1.5s, line-height 1.5s;
}

.noselect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}




* {
  box-sizing: border-box;
}

/* ============================= */
/* END SASS IMPORTS */
/* ============================= */


/* -------------------------------------- */
/* ----- HEADER & ref dropdown.css ------ */
/* -------------------------------------- */

/* IMPORTED */


/* ----------------------------------  */
/* ----- THEMATIC PAGE IMAGES  ------ */
/* ----------------------------------  */
.thematicImg {
  position: relative;
  max-width: 70vw;
  /* max-height: 40vh; */
  z-index: -1;
}

/* ------------------------ */
/* ----- LANDING PAGE ------ */
/* ------------------------ */
#landingPage {
  /* z-index: 16; */
  font-family: 'acier-bat-strokes', sans-serif;
  font-family: var(--AcierBatTextStrokesFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextStrokesFW);
  font-style: normal;
  font-style: var(--AcierBatTextStrokesFS);
  max-height: 100vh;
  max-height: 150vh;
  min-width: 100vw;
  margin: 0 auto;
  background-image: repeating-linear-gradient(to bottom right, rgb(41,41,41),rgb(41,41,41), rgb(25,25,25));
  background-image: repeating-linear-gradient(to bottom right, var(--grey-dark),var(--grey-dark), var(--grey-very-dark));
  /* background-image: repeating-linear-gradient(to bottom left,  var(--grey-very-dark), var(--grey-dark), var(--grey-dark), var(--grey-dark)); */
  /* display: -ms-grid; */
  /* display: grid; */
  /* grid-template-columns: repeat(5,1fr) 6fr;
  grid-template-rows: repeat(6,1fr);
  grid-template-areas: ; */
}




.citeArrival {
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 5fr;
      grid-template-columns: 4fr 5fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: .25fr .25fr;
      grid-template-areas: "intro seek";
  z-index: 190;
  top: -12vh;
  /* left: 10vw; */
  width: 100vw;
  min-height: 100vh;
  place-items: center;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)50%) */
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
}



.lizKaneIntro {
  grid-area: intro;
  margin: 10vh 0 0 3vw;
  position: relative;
  display: -ms-grid;
  display: grid;
  max-height: 60vh;
      grid-template-columns: repeat(1fr,1);
  -ms-grid-rows: (1fr)5;
      grid-template-rows: repeat(6, 1fr);
      grid-template-areas: "p1" "p2" "p3" "p4" "p5" "p6";
  transition: color 0s ease-in-out;
}

#Liz {
  /* grid-area: Liz; */
  /* font-size: 6vw; */
}

.lizKaneIntro>#p1 {
  grid-area: p1;
}

.lizKaneIntro> #p2 {
  grid-area: p2;
}

.lizKaneIntro> #p3 {
  grid-area: p3;
}

.lizKaneIntro> #p4 {
  grid-area: p4;
}

.lizKaneIntro> #p5 {
  grid-area: p5;
}

.lizKaneIntro> #p6 {
  grid-area: p6;
}
/* #p4 {
  grid-area: p4;
} */

.lizKaneIntro > p {
  /* transition-delay: 200ms; */
  transition: 0s font-family;
  color: #327E8A;
  color: var(--blue);
  margin: 0 auto;
  font-size: 1.9vw;
  line-height: 2vw;
  text-align: center;
  transition: all 1.5s ease-in-out;
  transition-timing-function: cubic-bezier(.6,0,.17,1.01)
}

.lizKaneIntro > #Liz:hover {
  /* font-family: var(--AcierBatTextGrisFF);
  font-weight: var(--AcierBatTextGrisFW);
  font-style: var(--AcierBatTextGrisFS); */
  transition-delay: 4s;
  /* transition-timing-function: ease-in-out; */
  /* transition-timing-function: cubic-bezier(.6,0,.17,1.01); */
  /* transition: 1000ms ease;
  transition-delay: 900ms; */
}

.solid {
  /* font-family: var(--AcierBatTextSolidFF); */
  /* font-family: var(--AcierBatTextOutlineFF); */
  /* font-family: var(--AcierBatTextGrisFF); */
  font-family: 'acier-bat-noir', sans-serif;
  font-family: var(--AcierBatTextNoirFF);
  /* font-weight: var(--AcierBatTextSolidFW);
  font-style: var(--AcierBatTextSolidFS); */
  font-size: 3.4vw;
  /* font-size: 1em; */
  /* transition: all 1.5s ease-in-out; */
  /* transition-timing-function: cubic-bezier(.6,0,.17,1.01) */
  /* font-family: var(--Chic); */
  /* font-weight: var(--AcierBatTextStrokesFW); */
  /* font-weight: 200; */
  font-style: normal;
  font-style: var(--AcierBatTextStrokesFS);
}

.strokes {
  font-size: 3.2vw;
  font-family: 'acier-bat-strokes', sans-serif;
  font-family: var(--AcierBatTextStrokesFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextStrokesFW);
  font-style: normal;
  font-style: var(--AcierBatTextStrokesFS);
  /* transition: all 1.5s ease-in-out;
  transition-timing-function: cubic-bezier(.6,0,.17,1.01) */
}
/* 
#seekLogoContainer {
  grid-area: seek;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: show;
  -webkit-perspective: 40px;
          perspective: 40px;
}

#seekLogoContainer > img {
  position: absolute;
  grid-area: seek;
  top: -20vh;
  left: -6.5vw;
}

#seekPhoto13 {
  top: 10;
  z-index: 13;

}

#seekPhoto15 {
  top: 5;
  z-index: 0;
}

#seekPhoto17 {
  top: 0;
  z-index: 0;
  opacity: .8;
}

#seekPhoto19 {
  top: 0;
  z-index: 0;
  opacity: .8;
  background-blend-mode: hard-light;
}

#seekPhoto21 {
  top: 10;
  z-index: 21;
}

#seekPhoto23 {
  top: 10;
  z-index: 23;
} */

/* ------------------------------------------------------------ */
/* ----------------- RESUME PAGE ------------------------------ */
/* ------- ref classes = .thematicImg .responsive-img --------- */
/* ---------------- ref FLOAT DOC BOX  ------------------------ */
/* ------------------------------------------------------------ */
h2 {
  /* font-family: var(--AcierBatTextNoirFF);
  font-weight: var(--AcierBatTextNoirFW);
  font-style: var(--AcierBatTextNoirFS); */
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 4vw;
  margin-right: 20vw;
}

#resumePage {
  /* margin-top: 10vh; */
  padding-top: 18vh;
  min-height: 100vh;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
}

/* thematic img container */
.redbox {
  display: -ms-grid;
  display: grid;
  place-items: center;
  grid-gap: 8vmin;
  grid-gap: var(--space);
  /* margin: 0 auto; */
  padding: var(--size/7) var(--size/7);
}

/* mix-blend-mode */
.promo {
  position: relative;
  cursor: pointer;
  width: 60vmin;
  width: var(--size);
  height: 60vmin;
  height: var(--size);
  padding: var(--size/7) var(--size/7);
}

#resumeAnima{
  position: absolute;
  font-size: 2.5vw;
  width: 100%;
  left: -10vw;
  top: -5vw;
  line-height: 3vw;
  text-align: center;
}


.docTitle {
  --font-size: calc(var(--size) / 8);
  display: flex;
  align-items: center;
  position: absolute;
  /* left: 8; */
  left: 0;
  bottom: 0;
  color: #EDC168;
  color: var(--yellow);
  font-size: var(--font-size);
  font-weight: 700;
  line-height: 1.2;
  white-space: nowrap;
  transform: translate(-10%, -50%);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform var(--duration) var(--ease-out);
  pointer-events: auto;
}

.redbox > .activeDocTitle {
  font-size: 2.4vw;
  display: block;
  /* flex-flow: row nowrap; */
  opacity: 0;
  z-index: 10;
  position: absolute;
  padding: 10 0 0 10%;
  
}

.redbox:hover > .activeDocTitle {
  opacity: 1;
  display: block;
}

.docTitle::after {
  content: attr(data-cta);
  display: inline-block;
  margin-left: 1.5vmin;
  font-size: calc(var(--font-size) / 3.25);
  font-weight: 400;
  letter-spacing: 0.125vmin;
  opacity: 0;
  transform: translateX(-25%);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1), opacity 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform var(--duration) var(--ease-out), opacity var(--duration) var(--ease-out);
}

.image-wrapper {
  width: 60vmin;
  width: var(--size);
  height: 60vmin;
  height: var(--size);
  padding: var(--size/7);
  overflow: hidden;
  -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
          clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1), -webkit-clip-path 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1), clip-path 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1), clip-path 450ms cubic-bezier(0.25, 1, 0.5, 1), -webkit-clip-path 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform var(--duration) var(--ease-out), -webkit-clip-path var(--duration) var(--ease-out);
  transition: transform var(--duration) var(--ease-out), clip-path var(--duration) var(--ease-out);
  transition: transform var(--duration) var(--ease-out), clip-path var(--duration) var(--ease-out), -webkit-clip-path var(--duration) var(--ease-out);
}

.image-wrapper img {
  position: relative;
  width: 120%;
  height: 100%;
  object-fit: cover;
  transform: translateX(-10%);
  transition: transform 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: transform var(--duration) var(--ease-out);
}

.image-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-color);
  mix-blend-mode: multiply;
  opacity: 0;
  transform: translateZ(0);
  transition: all 450ms cubic-bezier(0.25, 1, 0.5, 1);
  transition: all var(--duration) var(--ease-out);
}

.promo:hover img {
  transform: translateX(0);
}

.promo:hover .image-wrapper {
  -webkit-clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
          clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  transform: translateX(40%);
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
          transition-timing-function: var(--bounce-out);
}

.promo:hover .title {
  transform: translate(5%, -25%);
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
          transition-timing-function: var(--bounce-out);
}

.promo:hover .title::after {
  opacity: 1;
  transform: translateX(0);
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
          transition-timing-function: var(--bounce-out);
}

.promo:hover .image-wrapper::after {
  opacity: 1;
}

/* ------------------------------------------------------------ */
/* ----- EDUCATION PAGE --------------------------------------- */
/* ------- ref classes = .thematicImg .responsive-img --------- */
/* ------------------------------------------------------------ */
#educationPage {
  min-height: 100vh;
  width: 100vmin;
  margin-top: -7vw;
  font-family: 'Lora', serif;
  font-family: var(--Lora);
}

#educationLanding {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "viewbox docs";
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
  
}

#viewBoxContainer {
  grid-area: viewbox;
  background-image: url(/static/media/grandCanyon.4546ebdf.png);
  background-size: cover;
  height: 64vh;
  width: 60vw;
  margin: 0 2vw;
  border: 2px 2px solid none;
}

#edDocContainer {
  grid-area: docs;
  margin: 0 auto;
  opacity: 0;
  width: 35vw;
  transition: opacity .6s ease-in-out;
  /* -webkit-transform: translate(-35%, 0%);
  transform: translate(-35%, 0%); */
  /* background-color: var(--red); */
}

#eduViewBoxFill {
  background-image: repeating-linear-gradient(to bottom right, rgb(41,41,41), rgb(25,25,25));
  background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark));
}

#edDocContainer>h2.edDocName {
  padding: 5%;
  width: 90%;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 4vw;
}

#edDocContainer>h2.edDocName#mastersAnima {
  /* background-color: white; */
  /* border-radius: .8em; */
  /* border-radius: 1.6vw; */
  /* border-radius: .8vw; */
}


svg #educationSVG {
  display: block;
  margin: 3vmin auto;
  width: 40vmin;
  height:auto;
}

.edFloatText {
  font-size: 10.6vw;
  transition:font-size 600ms ease-out;
  font-weight: 600;
  font-family: arial;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  /* font-family: var(--Chic); */
}

/* Large screens ----------- */
@media only screen  and (min-width : 1824px) {
  #viewBoxContainer {
    height: 54vh;
     width: 45vw;
  }
  
  svg #educationSVG {
    display: block;
    margin: 3vmin auto;
  }
  
  .edFloatText {
    font-size: 8.6vw;
    transition:font-size 600ms ease-out;
    font-weight: 600;
    font-family: arial;
  }
}

#educationSVG:hover text{
  transition:font-size .4s ease-in;
  font-size: 550vw;
} 

#educationLanding:hover #edDocContainer{
  opacity: 1;
  /* -webkit-transform: translate(15%, 0%);
  transform: translate(15%, 0%); */
  transition:all 600ms ease-in-out;
}







/* ------------------------ */
/* ----- FLOAT DOC BOX ------ */
/* ------------------------ */
.ResourceListComponent {
  /* -webkit-transition: 0s color ease-in-out;
  transition: 0s color ease-in-out; */
}

.docName {
  cursor: pointer;
  color: #EDC168;
  color: var(--yellow);
  text-align: center;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 3vw;
}

.wrapperFloatDocBox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  overflow: hidden;
}

.floatDocBox {
  background-color: #EDC168;
  background-color: var(--yellow);
  position: relative;
  min-width: 80vw;
  min-height: 80vh;
  z-index: 99;
  margin: none;
  background-color: rgba(248,248,248,.8);
  background-color: var(--white-semi-trans);
  box-shadow: 0px 0px .5em rgb(25,25,25);
  box-shadow: 0px 0px .5em var(--grey-very-dark);
  -moz-box-shadow: 0px 0px 5px rgb(25,25,25);
  -moz-box-shadow: 0px 0px 5px var(--grey-very-dark);
  -webkit-box-shadow: 0px 0px 5px rgb(25,25,25);
  -webkit-box-shadow: 0px 0px 5px var(--grey-very-dark);
  -o-box-shadow: 0px 0px 5px rgb(25,25,25);
  -o-box-shadow: 0px 0px 5px var(--grey-very-dark);
  border-radius: 25px;
  /* overflow: scroll; */
  overflow: hidden; 
}

.resourceDiv {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: .05fr .06fr .07fr .1fr .1fr 4.4fr;
      grid-template-rows: .05fr .06fr .07fr .1fr .1fr 4.4fr;
      grid-template-areas: "category iframe iframe iframe " "name iframe iframe iframe" "logo iframe iframe iframe" "description iframe iframe iframe" "emblem iframe iframe iframe" ".  iframe iframe iframe";
  contain: content;
  position: absolute;
  max-width: 100%;
  min-height: 85vh;
  margin: 0 auto;
  border-radius: 25px;
}

.resourceCategory {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: category;
  position: relative;
  align-self: flex-start;
  margin: -2% 0 0 0;
  text-align: center;
  color: #EDC168;
  color: var(--yellow);
  background-color: #327E8A;
  background-color: var(--blue);
  padding: 1.9% 0;
  font-family: 'acier-bat-outline', sans-serif;
  font-family: var(--AcierBatTextOutlineFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextOutlineFW);
  font-style: normal;
  font-style: var(--AcierBatTextOutlineFS);
  font-size: 3.4vw;
  max-height: 100%;
}

.resourceName {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: name;
  position: relative;
  contain: content;
  -ms-grid-row-align: center;
  align-self: center;
  padding-top: 3%;
  margin: 0 auto;
  text-align: center;
  font-family: 'acier-bat-outline', sans-serif;
  font-family: var(--AcierBatTextOutlineFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextOutlineFW);
  font-style: normal;
  font-style: var(--AcierBatTextOutlineFS);
  font-size: 2.3vw;
  line-height: 2.5vw;
  color: #327E8A;
  color: var(--blue);
}

.resourceLogo {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: logo;
  position: relative;
  max-width: 100%;
  max-height: 90%;
  contain: content;
  margin: 0 auto;
  -ms-grid-row-align: center;
  align-self: center;
}

.resourceEmblem {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: emblem;
  position: relative;
  contain: content;
  max-width: 98%;
  margin: 0 auto;
  -ms-grid-row-align: center;
  align-self: center;
}

#GACertificateEmblem {
  margin-top: 25%;
}

#BachelorsDegreeEmblem {
  margin-top: 5%;
  width: 72%;
}

#MastersDegreeEmblem {
  width: 72%;
}
#ResumeEmblem {
  width: 72%;
}

.iframeDescription {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: description;
  /* position: relative;  */
  contain: content;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
  line-height: 1.8vw;
  margin: 0 auto;
  bottom: 0;
  font-family: 'acier-bat-outline', sans-serif;
  font-family: var(--AcierBatTextOutlineFF);
  font-weight: 400;
  font-weight: var(--AcierBatTextOutlineFW);
  font-style: normal;
  font-style: var(--AcierBatTextOutlineFS);
  font-size: 1.8vw;
  color: #8B0124;
  color: var(--red);
}

.responsiveIframe {
  -ms-grid-row: 1;
  -ms-grid-row-span: 6;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: iframe;
  width: 100%;
  height: 0;
  /* padding-bottom: 56.25%; */
  /* padding-bottom: 89%; */
  padding-bottom: 67.5%;
  position: relative;
  margin: 0 auto;
}

.responsiveIframe iframe {
  position: absolute;
  width: 100%;
  height: 100vmin;
  margin: 0 auto 0vh auto;
  overflow: scroll;
}



/* ---------------------------------------------------------- */
/* ----- PROJECT PAGE --------------------------------------- */
/* ------- ref classes = .thematicImg .responsive-img ------- */
/* --------- ref ProjectList -------------------------------- */
/* --------- STYLE ref project.scss  ------------------------ */
@-webkit-keyframes rotating {
  0%  { transform: rotate(0deg);}
  100%{ transform: rotate(360deg);}
}
@keyframes rotating {
  0%  { transform: rotate(0deg);}
  100%{ transform: rotate(360deg);}
}

/* @import "./ProjectStyle/project.css"; */
.flex-container {
  display: flex;
  flex-direction: row;
}

.flex-container>* {
  flex: 50% 1;
}

@media (max-width: 800px) {
  .flex-container {
      flex-direction: column;
  }
}

#projectsPage {
  margin: 0vh 0 0vh 0;
  padding: 0 0 15vh 0;
  position: relative;
  display: flex;
  place-content: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow: show;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
  /* background-image: url("../components/ResourcesProjects/UploadPhotosToWeb/docs/projectsBeeGridXWide.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.row {
  min-width: 100vmin;
  max-width: 100vw;
  min-height: 80vh;
  max-height: 95vh;
  padding: 11vh 0 0 0;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "work projects", "work projects";
  grid-gap: 1vw;
  z-index: 10;
  background-image: url(/static/media/projectsBeeGridXWide.0408b990.png);
  background-position: baseline;
  background-repeat: no-repeat;
  background-size: cover;
}

#teamWorkGridParent {
  grid-area: work;
  padding: 0 8vw 0 0;
  margin: 0vh 0 0 0;
}



li.skillLi{
  width: 4vw;
  height: 2vh;
  border-radius: none;
  padding: 0 0;
  margin: 0 0;
}


/*Change icons circle size and color here*/
.social-icons .fa {
  font-size: 3vw;
  margin: 0 0;
	text-align: center;
  position: absolute;
	transition: all 0.3s ease-in-out;
  line-height: 2vw;
  padding: .9vw;
  color: #EDC168;
  color: var(--yellow);
  -webkit-animation: rotating 8s linear infinite;
          animation: rotating 8s linear infinite;
  box-shadow: 0 0 .1em rgb(25,25,25);
  box-shadow: 0 0 .1em var(--grey-very-dark);
}

.social-icons.icon-circle .fa{ 
	border-radius: 50%; 
	border-radius: var(--border-radius-circle);
}

/* ---- SOCIAL ICONS FOR SKILLS DISPLAY ---- */
.list-unstyled {
  list-style: none;
}

.social-icons.icon-rotate .fa:hover, .social-icons.icon-rotate .fa:active { 
  -webkit-animation-play-state: paused; 
          animation-play-state: paused;
  color: #327E8A;
  color: var(--blue);
}

#floatBoxSkillList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

#floatBoxSkillList> li .skillLi{
  width: 1.5vw;
  height: 1.5vh;
  margin: 0 1vw .5vw 1vw;
}
#floatBoxSkillList>li .skillLi>.social-icons .fa {
  font-size: 2vw;
  text-align: center;
}

.social-icons.icon-spin .fa {
  font-size: 2vw;
  line-height: 1.5vw;
  padding: .25vw;
  -webkit-animation: none;
          animation: none;
  text-shadow: 2px 2px 2px rgb(25,25,25);
  text-shadow: 2px 2px 2px var(--grey-very-dark);

}

.social-icons.icon-spin .fa:hover, .social-icons.icon-spin .fa:active {
 transform: scale(1.1) rotate(360deg);
  color: #8B0124;
  color: var(--red);
}

#skillName {
  height: 1em;
}
/* SOCIAL SKILLS ICONS GRID AREAS */
#FaReact {
  grid-area: A1;
}
#SiJavascript {
  grid-area: A2;
}
#DiDjango {
  grid-area: A3;
}
#SiCss3 {
  grid-area: A4;
}
#SiAtom {
  grid-area: B1;
}
#DiSass {
  grid-area: B2;
}
#FaHtml5 {
  grid-area: B3;
}
#FaPython {
  grid-area: B4;
}
#SiMongodb {
  grid-area: C1;
}
#SiMysql {
  grid-area: C2;
}
#SiPostgresql {
  grid-area: C3;
}
#DiJqueryLogo {
  grid-area: C4;
}
#SiTypescript {
  grid-area: D1;
}
#Github {
  grid-area: D2;
}
#Bootstrap {
  grid-area: D3;
}
#FaGitAlt {
  grid-area: D4;
}
#SiFirebase {
  grid-area: F1;
}
#SiVisualstudio {
  grid-area: F2;
}
#FaCodepen {
  grid-area: F3;
}
#FaSlack {
  grid-area: F4;
}
#FaTrello {
  grid-area: G1;
}
#SiAdobeaftereffects {
  grid-area: G2;
}
#SiAdobecreativecloud {
  grid-area: G3;
}
#SiAdobelightroomclassic {
  grid-area: G4;
}
#SiAdobephotoshop {
  grid-area: H1;
}
#SiAdobexd {
  grid-area: H2;
}
#SiAdobe {
  grid-area: H3;
}
#SiNotion {
  grid-area: H4;
}
#SiAirtable {
  grid-area: I1;
}
#DiHeroku {
  grid-area: I2;
}
#DiMaterializecss {
  grid-area: DE1;
}
#FaNodeJs {
  grid-area: DE2;
}
#FaNpm {
  grid-area: DE3;
}
#SiBabel {
  grid-area: DE4;
}

#teamWorkGrid {
  /* height: 99%;
  width: 99%; */
  padding: 0 0;
  min-height: 60vmin;
  max-height: 90vh;
  min-width: 50vmin;
  max-width: 50vw;
  overflow: show;
  transform: rotate(4deg);
  display: grid;
  grid-template-columns: .1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: .5fr .5fr .5fr .5fr .5fr .5fr .5fr .5fr .5fr .15fr;
  /* column-gap: 2.35em; */
  grid-column-gap: 2.5vw;
  -webkit-column-gap: 2.5vw;
          column-gap: 2.5vw;

  
  grid-template-areas:
    ".  A1 .  A2 .  A3 .  A4"
    "B1 .  B2 .  B3 .  B4 . "
    ".  C1 .  C2 .  C3 .  C4"
    "D1 .  D2 .  D3 .  D4 . "
    ".  DE1 .  DE2 .  DE3 .  DE4"
    "F1 .  F2 .  F3 .  F4 . "
    ".  G1 .  G2 .  G3 .  G4"
    "H1 .  H2 .  H3 .  H4 . "
    ".  I1 .  I2 .  . .  ."
    ;
}



/* ========= Project Card SECTION ========= */
#projectBoardParent{
  grid-area: projects;
  display: flex;
  flex-flow: row wrap;
  max-height: 85vh;
  margin-top: -10vh;
  transform: rotate(4deg);
}

.projectBoard {
  min-height: 70vh;
  margin: 0 -3vw 0 2vw;
  max-height: 85vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  grid-gap: .5vw;
}

.projectboard > .projectNameLogo {
  display: flex;
  align-content: center;
  width: 100%;
  z-index: 0;
}

.projectLogoInactive {
  width: 100em;
  background-color: rgb(41,41,41);
  background-color: var(--grey-dark);
}

.projectName {
  font-size: 3vw;
  margin: 0 5%;
}



/* PROJECT CARD MAIN ANIMATION DETAILS -- START */
li.rightTop>p {
  text-decoration: none;
  color: #EDC168;
  color: var(--yellow);
}

.card {
  flex: 26% 1;
  float: left;
  padding: 2%;
  width: 100%;
  height: 48%;
  margin: 1%;
  flex-direction: row wrap;
}

.card .menuContent {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.card .menuContent::before, .card .menuContent::after {
  content: '';
  display: table;
}

.card .menuContent::after {
  clear: both;
}

.card .menuContent li {
  display: inline-flex;
}

.card .menuContent li.rightTop .rightTopIcons {
  color: #EDC168;
  color: var(--yellow);
  margin: -12% 5% 0;

}
.rightTopIcons >span {
  display: inline-block;

}
.card .menuContent span {
  position: absolute;
  left: 50%;
  top: 0;
  color: rgba(248,248,248,.8);
  color: var(--white-semi-grey);
  font-size: 1.5em;
  font-weight: 700;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  transform: translate(-50%, 0%);
}

.card .wrapper {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(rgb(25,25,25), 0.3), 0 15px 12px rgba(rgb(25,25,25), 0.2);
          box-shadow: 0 19px 38px rgba(var(--grey-very-dark), 0.3), 0 15px 12px rgba(var(--grey-very-dark), 0.2);
}

.card .logoContainer {
  position: relative;
  opacity: 1;
  margin-top: 4em;
  text-align: center;
  align-content: center;
  transition: opacity .3s ease-in-out;
}

.card .wrapper:hover .logoContainer {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.card .wrapper:hover .data {
  transform: translateY(0);
}

.card .data {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(70px + 1em));
  transition: transform 0.3s ease-in;
}

.card .data .hiddenContent {
  padding: .5em;
  position: relative;
  z-index: 1;
  text-align: center;
}

.card .devName {
  font-size: 1.3em;
  text-align: left;
  -ms-grid-row-align: start;
  align-self: start;
}

.card .projectCardTitle a {
  font-size: 2em;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-weight: 300;
}

.card .containerText {
  margin: .2em 0 .5em 0;
  max-height: 5em;
  overflow: scroll;
  color: #EDC168;
  color: var(--yellow);
  padding: .02em;

}

.card .text {
  font-size: .6em;
  font-family: Helvetica, sans-serif;
}

.card input[type='checkbox'] {
  display: none;
}

.card input[type='checkbox']:checked + .menuContent {
  transform: translateY(-60px);
}

.projectCard {
  border: .7px solid #EDC168;
  border: .7px solid var(--yellow);
  cursor: pointer;
  background-color: rgba(25,25,25,.2);
  transition: background 0.3s;
}

.projectCard:hover {
  background-color: rgb(41,41,41);
  background-color: var(--grey-dark);
  transition: background 0.3s;

}

.projectCard .wrapper:hover .menuContent span {
  transform: translate(-50%, -90%);
  opacity: 1;
  font-size: .6em;
  
}

.projectCard .projectHeader {
  color: #EDC168;
  color: var(--yellow);
  padding: 1em;
}

.projectCard .projectHeader::before, .projectCard .projectHeader::after {
  content: '';
  display: table;
}

.projectCard .projectHeader::after {
  clear: both;
}

.projectCard .projectHeader .leftTop {
  float: left;
  font-size: .8em;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}

.projectCard .projectHeader .rightTop {
  float: right;
  font-size: .6em;
}

.projectCard .menuContent {
  float: right;
}

.projectCard .menuContent li {
  position: relative;
}

.projectCard .menuContent span {
  transition: all 0.3s;
  opacity: 0;

}

/* PROJECT DESCRIPTION */
.projectCard .data {
  color: #EDC168;
  color: var(--yellow);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 1.5em;
  transform: translateY(calc(70px + 4em));
}

/* PROJECT TITLE */
.projectCard .projectCardTitle p {
  font-size: 1.3em;
  color: #EDC168;
  color: var(--yellow);
  transition: all .3s ease-in-out;
}

/* CHECK IT OUT */
.projectCard .projectButton {
  display: block;
  width: 70%;
  margin: .2em auto 0;
  text-align: center;
  font-size: .6em;
  color: #EDC168;
  color: var(--yellow);
  line-height: 1;
  position: relative;
  font-weight: 700;
  cursor: pointer;
}

.projectCard .projectButton::after {
  content: '\2192';
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.3s;
}

.projectCard .projectButton:hover::after {
  transform: translate(5px, -50%);
  opacity: 1;
}
/* PROJECT CARD MAIN ANIMATION DETAILS -- END */





/* ------------------------ */
/* ----- FLOAT BOX ------ */
/* ------------------------ */
.wrapperFloatBox {
  /* background-color: var(--white-semi-trans); */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -8.5vh;
  left: -55vw;
  width: 100vw;
  height: 100vh;
  z-index: 299;
  transform: rotate(-4deg);
}

.floatBox {
  position: relative;
  min-width: 80vw;
  max-width: 80vw;
  min-height: 85vh;
  margin: none;
  background-color: rgba(25,25,25,.9);
  box-shadow: 0 0 .8em rgb(25,25,25);
  box-shadow: 0 0 .8em var(--grey-very-dark);
  -moz-box-shadow: 0 0 .8em rgb(25,25,25);
  -moz-box-shadow: 0 0 .8em var(--grey-very-dark);
  -webkit-box-shadow: 0 0 .8em rgb(25,25,25);
  -webkit-box-shadow: 0 0 .8em var(--grey-very-dark);
  -o-box-shadow: 0 0 .8em rgb(25,25,25);
  -o-box-shadow: 0 0 .8em var(--grey-very-dark);
  border-radius: 25px;
  overflow: hidden;
  color: #fff;
  color: var(--white-pretty);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}

.projectNameLogo {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 7;
  grid-area: nameLogo;
  margin: -5vh 0 0 0;
  padding: .8vw;
  background-color: rgba(25,25,25,.9);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);

  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.projectName {
  flex: 2 1;
  font-size: 4.6vw;
  margin: 0 0 0 25%;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  color: #EDC168;
  color: var(--yellow);
}

.projectLogoActive {
  margin: 0 1.5vw;
  max-height: 100%;
}

.projectPhotos {
  max-height: 100%;
  min-height: 50%;
  place-items: center;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  border-radius: 25px;
}

/* place-items: center;
  background-color: var(--white-pretty);
  background-color: var(--grey-dark-light);
  font-family: var(--Chic);
  color: var(--white-pretty);
  color: var(--grey-dark);
  border-radius: 25px; */

.projectDescription {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: description;
  position: relative;
  font-size: 15px;
  overflow: scroll;
  place-items: center;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  color: #EDC168;
  color: var(--yellow);
  border-radius: 25px;
  padding: 1vw 2vw;
  background-color: rgba(25,25,25,.9);
  transition: all 200ms ease-in-out;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: scroll;
  position: relative;
  max-height: 100%;
  padding: 2vw;
  margin-top: -3.5em;
}

.carousel-description {
  overflow: scroll;
  margin-top: .5vw;
}
.carousel-description {
  font-size: 1.35vw;
  line-height: 1.35vw;
}

.carousel-image-container {
  overflow: scroll;
  place-items: center;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  text-align: center;
}

/* Wrap the image and alt text */
/* Will be added with js */
.alt-wrap {
  display: block;
  position: relative;
  margin: 1.5em;
  overflow: scroll;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  color: #EDC168;
  color: var(--yellow);
}

.carousel-image-container > .carousel-description{
  overflow: scroll;
}

.projectGalleryImage {
  max-width: 95%;
  max-height: 50%;
}

.carouselBtn {
  position: relative;
  padding: 0% auto;
  color: #EDC168;
  color: var(--yellow);
  font-weight: 900;
  min-height: 50%;
  max-height: 50%;
}

#left-button, #right-button {
  margin-top: 22%;
  width: 3vw;
  height: 3vw;
  background-color: none;
  border: none;
  z-index: 300;
}
#left-button>i, #right-button>i {
  color: #EDC168;
  color: var(--yellow);
  font-size: 2.3vw;
}

#left-button {
  margin-left: 4.4vw;
}

#right-button {
  margin-right: 4.4vw;
}

.projectMore {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-area: more;
  padding: 2em;
  overflow: scroll;
  border-radius: 25px;
  transition: all 200ms ease-in-out;
}

.projectGallery {
  -ms-grid-row: 2;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  grid-area: gallery;
  display: fixed;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  overflow: hidden;
  padding: 1em;
  border-radius: 25px;
  transition: all 200ms ease-in-out;
}

.projectDescription:hover, .projectGallery:hover, .projectMore:hover {
  /* background-color: rgb(59,59,59); */
  background-color: rgba(25,25,25,.9);
  transition: all 200ms ease-in-out;
}

.projectPhotos {
  /* margin: .4vw; */
  margin: 0 auto;
  max-height: 100%;
}

.projectGithub {
  position: -webkit-sticky;
  position: sticky;
  grid-area: githublinks;
  display: flex;
  justify-content: center;
  margin-bottom: -4vh;
}

.languageIconTextContainer {
  grid-area: iconText;
  max-height: 1vw;
  min-height: 1vw;
}

.languageIconTextContainer>p{
  text-align: left;
  font-size: 3.5vw;
  font-weight: 700;
  color: #8B0124;
  color: var(--red);
  padding-left: 13vw;
}

#githubProject1, #githubProject2{
  font-size: 1.3vw;
  color: rgba(124,85,32,1);
  color: var(--yellowDark);
  text-shadow: .25px 0px 1px #EDC168;
  text-shadow: .25px 0px 1px var(--yellow);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  padding: 2vw;
}

#githubProject1:hover, #githubProject2:hover {
  color: #EDC168;
  color: var(--yellow);
  text-shadow: .25px 0px 1px rgba(0,0,0,0);
  text-decoration: none;
}

.githubIFrame {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.githubIFrame2 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.projectCode {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  position: relative;
  display: -ms-grid;
  display: grid;
  margin: 5px 0;
  contain: content;
  overflow: scroll;
}

.responsiveIframeProject {
  -ms-grid-columns: 1fr .2fr;
      grid-template-columns: 1fr .2fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* padding-bottom: 80%; */
  position: relative;
}

.projectContainer {
  display: -ms-grid;
  display: grid;
  padding-top: 5vh;
  max-width: 80vw;
  max-height: 80vh;
  contain: content;
  -ms-grid-columns: (1fr)7;
      grid-template-columns: repeat(7, 1fr);
  -ms-grid-rows: 1fr 4.5fr 3fr .1fr .1fr;
      grid-template-rows: 1fr 4.5fr 3fr .05fr .1fr;
      grid-template-areas: "nameLogo nameLogo nameLogo nameLogo nameLogo nameLogo nameLogo" "description description description gallery gallery gallery gallery" "more more more gallery gallery gallery gallery" "iconText iconText iconText iconText iconText iconText iconText" "githublinks githublinks githublinks githublinks githublinks githublinks githublinks";
      grid-gap: .5em;
      
}


/* 
//---- SOCIAL ICONS FOR SKILLS DISPLAY ----
.list-unstyled {
	padding-left: 0;
	list-style: none;
}
.list-inline li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
	margin-bottom: 10px;
}
//---- General classes end -------

//Change icons size here
.social-icons .fa {
	font-size: 2.8em;
}
//Change icons circle size and color here
.social-icons .fa {
	width: 4em;
	height: 4em;
	line-height: 4em;
	text-align: center;
	color: #FFF;
	color: rgba(255, 255, 255, 0.8);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.social-icons.icon-circle .fa{ 
	border-radius: 50%;
}
.social-icons.icon-rounded .fa{
	border-radius:5px;
}
.social-icons.icon-flat .fa{
	border-radius: 0;
}

.social-icons .fa:hover, .social-icons .fa:active {
  color: var(--white-pretty);
	-webkit-box-shadow: .05em .05em .1em var(--grey-very-dark);
	-moz-box-shadow: .05em .05em .1em var(--grey-very-dark);
	box-shadow: .05em .05em .1em var(--grey-very-dark); 
}
.social-icons.icon-zoom .fa:hover, .social-icons.icon-zoom .fa:active { 
 	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2); 
}
.social-icons.icon-rotate .fa:hover, .social-icons.icon-rotate .fa:active { 
	-webkit-transform: scale(1.2) rotate(120deg);
	-moz-transform: scale(1.2) rotate(120deg);
	-ms-transform: scale(1.2) rotate(120deg);
	-o-transform: scale(1.2) rotate(120deg);
	transform: scale(1.2) rotate(120deg);
}
 */


/* ************************************** */
/* STOPPED CONVERTING PX / EM => VW HERE */
/* ************************************** */

/* ------------------------ */
/* ----- WORK PAGE ------ */
/* ------- ref classes = .thematicImg .responsive-img --------- */
/* ------------------------ */
#workPage {
  margin-top: -10vh;
  z-index: -1;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
}

#innerWorkPage {
  margin-top: 20vh;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
}

#desertImg {
  max-height: 80vh;
  margin: 0 5vw;
  border-radius: 25px;
  z-index: 5;
}
#workDescription{
  margin-top: 5vw;
}
#workDescription>* {
  margin: 0vh 5vw 2vh 0;
  font-family: 'Lobster';
  font-family: var(--Lobster);
  cursor: default;
  
}
#workDescription>h3 {
  font-size: 2.3vw;
  color: #8B0124;
  color: var(--red);
}
#workDescription>h3:hover {
  -webkit-filter: brightness(1.7);
          filter: brightness(1.7);
  transform: scale(1.05);
  transition: all 400ms ease-in-out
}

#workDescription>p {
  text-align: center;
  font-size: 1.6vw;
  color: #8B0124;
  color: var(--red);
}

#rfpBack {
  position: absolute;
}

#rfpInactive {
  font-size: 4em;
  cursor: pointer;
  /* margin: -70vh 0 0 -20VW; */
  margin: -60vh 0 3vw 30%;
  position: absolute;
  /* text-shadow: 2px 2px 5px var(--grey-very-dark); */
  font-family: 'Lobster';
  font-family: var(--Lobster);
  /* color: var(--yellow); */
  color: #8B0124;
  color: var(--red);
  /* text-shadow: 1em 1em 0px var(--grey-dark)  */
  /* text-shadow: 3em 7em 0px var(--blue); */
}

#hireLine {
  opacity: 1;
  margin: 1vh;
}
#rfpInactive:hover  {
  /* border-bottom: 3px groove var(--yellow); */
  /* transform: translate(25%, 0);
  transition: all 200ms ease-in-out; */
  /* border: 2px groove gainsboro; */
}

.workIcon .inactiveIcon {
  opacity: 0;
  color: #8B0124;
  color: var(--red);
  margin: 0 auto;
}

.workIcon .activeIcon {
  opacity: 1;
  transform: translate(25%, 0);
  transition: all 150ms;
}

#rfpInstructions>div>* {
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}
#rfpInstructions {
  grid-area: rfpInstructions;
  max-width: 25vw;
  max-height: 100vh;
  overflow: scroll;
  margin: 10vh 0 0 -3.75vw;
  padding: 0 1vw 10vh 0;
}

#rfpInstructions>div {
  display: flex;
  flex-flow: row wrap;
  padding: .5em .5em 0 .5em;
  margin: 1vh 0 0 3vw;
  position: relative;
  background-color: #fff;
  background-color: var(--white-pretty);
  box-shadow: 0px 1px .05em rgb(56,56,56);
  box-shadow: 0px 1px .05em var(--grey-dark-light);
  border-radius: 25px;
  transition: 150ms ease-in-out;

  font-size: 1.2em;
}
  
#rfpInstructions>div:hover {
  transform: scale(1.03);
  transition: all 150ms ease-in-out;
}

#rfpInstructions>h3, #rfpInstructions>div>h3, #rfpInstructions>div>h5, #rfpInstructions>h5 {
  text-align: center;
  margin: 0 auto;
  padding: .8vh 0;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);

}
#rfpInstructions>h3{
  font-size: 2em;
  position: absolute;
  margin: -10vh 0 0 2.6vw;
  background-color: #e8e8e8;
  background-color: var(--greyish);
  padding: .35vh 1vw .3vh 1vw;
}
#rfpInstructions>div>h3, #rfpInstructions>div>h5>span {
  margin: 0 auto;
}

#rfpInstructions>div>h3 {
  font-size: 1.2em;
}

#rfpInstructions>div>i {
  margin: -2.5vh 0 0 -3vw;
  position: absolute;
  cursor: pointer;
  font-size: 2em;
  transition: all 150ms ease-in-out;
}
#rfpInstructions>div>i:hover {
  transform: scale(1.03);
  transition: all 150ms ease-in-out;
}

#rfpInstructions>p, #rfpInstructions>div>p {
  font-size: 1em;
}
#rfpInstructions>h5 {
  text-decoration: underline;
  text-align: center;
}
#rfpInstructions>div>.browserBox,#rfpInstructions>div>.browserText, #rfpInstructions>div>.downloadBox, #rfpInstructions>div>.downloadText>span {
  font-weight: bold;
  color: #8B0124;
  color: var(--red);
}

#rfpInstructions>div>.browserBox:hover,#rfpInstructions>div>.browserText>span:hover, #rfpInstructions>div>.downloadBox:hover, #rfpInstructions>div>.downloadText>span:hover {
  color: #163a3f;
  color: var(--blue-dark);
  cursor: pointer;
}

#rfpIframeContainer {
  grid-area: rfpIframe;
  margin: 1vh 1vh; 
}
.floatBlogBox > .rFPContainer {
  min-width: 90vw;
  max-height: 100vh;
  overflow: hidden;
}

.strikeThrough {
  text-decoration: line-through;
  color: #327E8A;
  color: var(--blue);
}

/* ------------------------ */
/* ----- INTERESTS PAGE ------ */
/* ------- ref classes = .thematicImg .responsive-img --------- */
/* ------------------------ */
#interestsPage {
  position: relative;
  z-index: -1;
  /* background-image: repeating-linear-gradient(to bottom right, var(--grey-dark), var(--grey-very-dark)); */
  background-image: repeating-linear-gradient(to left,  rgb(25,25,25), rgb(41,41,41));
  background-image: repeating-linear-gradient(to left,  var(--grey-very-dark), var(--grey-dark));
}

#interestsLanding {
  background: none;
  padding-top: 10vh;
  margin-top: 5vh;
  min-height: 90vh;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "interestsHeadings interestsImage interestsText";
  }

.interestsBody {
  grid-area: interestsHeadings;
  margin: 0vh 4vw 0 3vw;
  max-height: 90vh;
  overflow-x: show;
}

.Interests-container {
  /* background: linear-gradient(45deg, #02001F, #1F1B4E); */
  transform-style: preserve-3d;
  transform: perspective(60rem);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: show;
}



#interestsHeadings {
  
  /* z-index:2; */
  /*
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: .5fr .5fr .5fr .5fr .5fr .5fr .5fr .5fr .5fr 1fr;
  row-gap: 2px;
  grid-template-areas: 
  "col1a . . . ."
  ". col2a . . ."
  ". . col3a . ."
  ". . . col4a ."
  ". . . . col5"
  ". . . col4b ."
  ". . col3b . ."
  ". col2b . . ."
  "col1b . . . ."
  ". . . . ."
  ; */
  
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  */
}

/* ----------------- */
/* - CENTER COLUMN - */
/* ----------------- */
#obsessionsImgContainer{
  grid-area: interestsImage;
  max-height: 75vh;
  max-width: 45vw;
  margin: 3vw auto 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

#obsessionsImg {
  max-height: 60vw;
  min-height: 40vw;
  max-width: 60vw;
  min-width: 40vw;
}

#obsessionsImgContainer>.Interests-list {
  position: absolute;
  margin-left: 7%;
  font-size: 5em;
  font-family: 'Lobster';
  font-family: var(--Lobster);
  text-align: center;
  color: rgb(34,34,34);
  color: var(--grey-very-dark2);
  cursor: default;
  
}

#obsessionsImgContainer>.Interests-list>li#obsessionsHeading>* {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 3px 4px 0px rgb(34,34,34);
  text-shadow: 3px 4px 0px var(--grey-very-dark2);
  /* color: var(--grey-very-dark2);
  text-shadow: 3px 4px 0px rgba(255, 255, 255, 0.8); */
}

#linkedInPosts>.Interests-list>li#linkedInPostLi>* {
  /* color: rgba(255, 255, 255, 0.8); */
  /* color: var(--grey-very-dark2); */
  /* color: var(); */
  /* text-shadow: 3px 4px 0px var(--grey-very-dark2); */
  text-shadow: none;
  
}

#iconTextContainer {
 min-height: 3em; 
}
/* ---------------- */
/* - LEFT COLUMN - */
/* ---------------- */

/* INTEREST HEADINGS ANIMATION */
.Interests-list {
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* transform: rotateX(-10deg) rotateY(20deg); */
  transform:  rotateY(20deg);

  font-family: 'Lobster';

  font-family: var(--Lobster);
  font-size: 3.5em;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-font-kerning: normal;
  -webkit-text-size-adjust: 100%;
}

.Interests-list-item {
  position: relative;
  color: transparent;
  cursor: pointer;
  text-shadow: 3px 4px 0px rgb(34,34,34);
  text-shadow: 3px 4px 0px var(--grey-very-dark2);
  /* text-shadow: 2px 1px 0px rgba(255, 255, 255, 0.6); */
}

.Interests-list-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 49%;
  left: -10%;
  right: -10%;
  height: 4px;
  border-radius: 4px;
  margin-top: -2px;
  background: #327E8A;
  /* background: var(--yellow); */
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0.16, 1.08, 0.38, 0.98);
  z-index: 1;
}

.InterestMask {
  display: block;
  position: absolute;
  overflow: hidden;
  color: #327E8A;
  top: 0;
  height: 49%;
  transition: all 0.8s cubic-bezier(0.16, 1.08, 0.38, 0.98);
}
.InterestMask span {
  display: block;
}

.InterestMask + .InterestMask {
  top: 48.9%;
  height: 51.1%;
}
.InterestMask + .InterestMask span {
  transform: translateY(-49%);
}

.Interests-list-item:hover .InterestMask,
.Interests-list-item:active .InterestMask {
  color: #FFF;
  transform: skewX(12deg) translateX(5px);
}
.Interests-list-item:hover .InterestMask + .InterestMask,
.Interests-list-item:active .InterestMask + .InterestMask {
  transform: skewX(12deg) translateX(-5px);
}
.Interests-list-item:hover::before,
.Interests-list-item:active::before {
  transform: scale(1);
}

/* ------------------------- */
/* WRAPPER FLOAT BOX STYLES */
/* ------------------------- */
.wrapperFloatBlogBox  {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
}

.floatBlogBox {
  position: relative;
  min-width: 90vw;
  min-height: 80vh;
  z-index: 99;
  margin: none;
  background-color: rgba(248,248,248,.8);
  background-color: var(--white-semi-trans);
  box-shadow: 0px 0px .5em rgb(25,25,25);
  box-shadow: 0px 0px .5em var(--grey-very-dark);
  -moz-box-shadow: 0px 0px 5px rgb(25,25,25);
  -moz-box-shadow: 0px 0px 5px var(--grey-very-dark);
  -webkit-box-shadow: 0px 0px 5px rgb(25,25,25);
  -webkit-box-shadow: 0px 0px 5px var(--grey-very-dark);
  -o-box-shadow: 0px 0px 5px rgb(25,25,25);
  -o-box-shadow: 0px 0px 5px var(--grey-very-dark);
  border-radius: 25px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.blogContainer, .rFPContainer {
  display: flex;
  contain: content;
  position: absolute;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  border-radius: 25px;
}




/* REF BlogList.js */
#blogListContainer {
  width: 90vw;
  height: 80vh;
  overflow: scroll;
  margin-bottom: 3vh;
}

#blogListUL {
  width: 100%;
  /* height: 105%; */
  height: 85vh;
  display: flex;
  /* margin-bottom: 5%; */
  box-sizing: border-box;
  flex-flow: row wrap;
}

.wrapperBlogPost {
  width: 25%;
  text-decoration: none;
}

Link.blogBoxLink{
  text-decoration: none;
}

.blogBox, .recPostBox {
  flex: 1 1;
  overflow: hidden;
  text-decoration: none;
  transition: 450ms;
  margin: 2.5%;
  box-shadow: 0 0 .8em rgb(25,25,25);
  box-shadow: 0 0 .8em var(--grey-very-dark);
  box-shadow: 0 .75em 1.5em rgb(34,34,34);
          box-shadow: 0 .75em 1.5em var(--grey-very-dark2);
  line-height: 0;
  height: 40vh;
  min-width: 23%;
  width: 23%;
  max-width: 30%;
  background-color: rgb(25,25,25);
  background-color: var(--grey-very-dark);
  border-radius: 10px;
  transition: all 200ms ease-in-out;
}

.recPostBox {
  background-color: rgba(0,0,0,0);
  height: 42vh;
  margin: 1.5% 2.5%;
}

.blogBox:hover, .recPostBox:hover {
  cursor: pointer;
  transform: scale(1.04);
  transition: all 200ms ease-in-out;
  text-decoration: none;
}

.blogBox>.blogBoxLink>.blogImg, .recPostBox>.recPostImg  {
  width: 150%;
  min-height: 80%;
  max-height: 80%;
  text-decoration: none;
  object-fit: cover;
  position: relative;
  transition: all 350ms ease-in-out;
}

.recPostBox > .recPostImg  {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* padding: 5%; */
  /* padding: 1em; */

}

.blogBox > .blogBoxLink > .blogTitle, .recPostBox >.recPostTitle {
  min-height: 20%;
  /* background-color: var(--greyish-semi-trans); */
  background-color: rgba(250,250,250,1);
  color: rgb(56,56,56);
  color: var(--grey-dark-light);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.blogBox > .blogBoxLink > .blogTitle, .recPostTitle>span {
  font-size: 2em;
  display: block;
  text-align: center;
  min-height: 100%;
  line-height: 1em;
  position: relative;
  text-decoration: none;
  /* color: var(--white-semi-grey); */
  color: rgb(80, 80, 80);
  color: var(--grey-dark-light4);
  
}
.blogBox > .blogBoxLink > .blogTitle , .recPostTitle>span:hover {
  text-decoration: none;
}

.blogBox:hover > .blogBoxLink > .blogImg  {
  width: 100%;
  cursor: pointer;
  transition: all 350ms ease-in-out;
}

/* .recPostBox:hover > .recPostImg */

#postEnlarged, #recPostEnlarged {
  background-color: #fff;
  background-color: var(--white-pretty);
  border-radius: 25px;
  margin: 2em;
  box-shadow: 0 0 1em #e8e8e8;
  box-shadow: 0 0 1em var(--greyish);
  overflow: hidden;
  position: relative;
  color: #327E8A;
  color: var(--blue);
  text-shadow: 1px 0px 0 rgba(25,25,25, 0.6);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}

#recPostEnlarged {
  min-width: 85vw;
}

#postHeader, #recPostHeader {
  justify-content: center;
  display: grid;
  grid-template-columns: .25fr 5fr;
  grid-template-rows: 1fr;
  grid-template-areas: "back posttitle";
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  border-bottom: 1px solid var(--greyish);
  width: 100%;
}

#postBackArrow, #recPostBackArrow {
  grid-area: back;
  font-size: 3.7em;
  line-height: 1.27em;
  color: #fff;
  color: var(--white-pretty);
  background-color: #e8e8e8;
  background-color: var(--greyish);
  border-bottom-right-radius: 25px;
  padding: 0em .2em .14em 0;
  transition: all 150ms ease-in-out;
}

#postBackArrow:hover, #recPostBackArrow:hover {
  line-height: 1.27em;
  padding-left: .5em;
  background-color: #737373;
  background-color: var(--greyish-solid);
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

#postClose, #recPostClose {
  margin: -1.5em 1em .25em 0;
  font-size: 2em;
  padding: 0 .3em .1em .3em;
  align-self: center;
  text-align: center;
  border-radius: 50px;
  transition: all .5s ease-in-out;
}


#postClose:hover, #recPostClose:hover{
  transform: scale(1.2);
  background-color: rgba(237, 237, 237, 0.3);
  box-shadow: 0 0 .7em rgba(148,148,148,.5);
  transition: all .5s ease-in-out;
}

.activeTitle {
  grid-area: posttitle;
  font-family: 'Lobster';
  font-family: var(--Lobster);
  font-size: 3.3em;
  line-height: 1em;
  padding: .2em;
  align-self: center;
  text-align: center;
  text-shadow: .04em .025em 0em rgba(40,40,40,1);
}

.blogBody, .recPostBody {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  padding: 1.5em 2em;
  width: 95%;
  margin: 0 auto;
  border-radius: 1.6vw;
  border-radius: var(--border-radius-25);
}

.recPostBody {
  margin-bottom: 3vh;
}

.blogSection, .recPostSection {
  /* flex: 1; */
  width: 100%;
  /* grid-area: postWriting; */
  /* max-width: 70%; */
  line-height: 1.7em;
  padding: 0 2em 0 0;
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
}

.blogSection>p {
  margin: .65em 0;
  font-size: 1.2em;
  color: rgb(25,25,25);
  color: var(--grey-very-dark);
  text-shadow: .03em 0 .0em rgb(25,25,25),
  0 0 3em rgba(250,250,250,.8),
  .1em .1em .7em rgba(250,250,250,.8),
  .3em .3em .9em rgba(250,250,250,.8),
  .5em .5em 1.2em rgba(250,250,250,.8);
  text-shadow: .03em 0 .0em var(--grey-very-dark),
  0 0 3em rgba(250,250,250,.8),
  .1em .1em .7em rgba(250,250,250,.8),
  .3em .3em .9em rgba(250,250,250,.8),
  .5em .5em 1.2em rgba(250,250,250,.8);
}
.recPostSection>p {
  margin: .65em 0;
  font-size: 1.8em;
  line-height: 1.2em;
}


.blogSection>p.blogP2.centerText {
  font-size: 1.2em;
  padding: 0 0 .4em 8em;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0;
}
.blogSection>p.centerText {
  padding-left: 10em;
  font-weight: bold;
  word-spacing: .6em;
}

.blogSection>p.blogP11 {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 0 1.5em 10em;
}

.blogSection>p.blogP18.signature {
  font-family: Lobster;
  font-size: 1.6em;
  margin-left: 2em;
}

/* .blogBody>div.image-wrapper, .recPostBody>div.image-wrapper {
  flex: 2;
  min-height: 98%;
  max-width: 42%;
  overflow: show;
  border-radius: 25px;
  position: float;
}

.recPostBody>div.image-wrapper {
  padding: 0 3%;
} */

.activeBlogImg {
  min-width: 100%;
}




/* ---------------------------- */
/* --- INTERESTS PAGE STILL --- */
/* --- Rolling Container ------ */
/* ---------------------------- */
.rollerContainer {
  display: flex;
  position: relative;
  width: 40%;
  height: 25%;
  /* margin: 5vh 0; */
  padding: 2vh 0;
  margin: -5vh 0 5vh 0vw;
  align-content: center;
  align-items: center;
}

.rollerContainer>.content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 2.5em;
  font-size: 2em;
  margin: 1em;
  /* width: 30em; */
  width: 100%;
  overflow: hidden;
  /* font-family: "Lato", sans-serif; */
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  font-size: 1em;
  line-height: 1.5em;
  background-color: none;
  display: flex;
  align-content: center;
}

.content__container {
  width: 95%;
  font-weight: 600;
  overflow: hidden;
  height: 5em;
  padding: 0 2em;
}

.content__container:before {
  content: "||";
  left: 0;
  /* text-shadow: 2px 2px 4px var(--yellowDark); */
}

.content__container:after {
  content: "||";
  position: absolute;
  right: 0;
  /* text-shadow: -2px 2px 4px var(--yellowDark); */
}

.content__container:after, .content__container:before {
  position: absolute;
  top: 0;
  /* color: var(--yellow); */
  color: #327E8A;
  color: var(--blue);
  /* text-shadow: 2px 2px 4px var(--yellowDark); */
  /* color: #16a085; */
  font-size: 3.4em;
  line-height: .7em;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.48, 0, 0.47, 1);
  animation-name: opacity;
  animation-duration: 2.5s;
  animation-timing-function: cubic-bezier(0.48, 0, 0.47, 1);
  animation-iteration-count: infinite;
}

.content__container__text {
  font-size: 2em;
  /* color: var(--yellow); */
  color: rgba(248,248,248,.8);
  color: var(--white-semi-grey);
  font-family: 'Bodoni Moda', serif;
  font-family: var(--Chic);
  line-height: 1em;
  display: inline;
  float: left;
  margin: 0;
}

.content__container__list {
  margin-top: 0;
  text-align: center;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  overflow: hidden;
}

.content__container__list__item {
  background-color: #fff;
  background-color: var(--white-pretty);
  font-size: 1.7em;
  line-height: 1.3em;
  margin: 0;
  text-align: center;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%, 10% {
    transform: translate3d(0, 0, 0);
  }
  20% {
    transform: translate3d(0, -10%, 0);
  }
  30% {
    transform: translate3d(0, -20%, 0);
  }
  40% {
    transform: translate3d(0, -30%, 0);
  }
  50% {
    transform: translate3d(0, -40%, 0);
  }
  60% {
    transform: translate3d(0, -50%, 0);
  }
  70% {
    transform: translate3d(0, -60%, 0);
  }
  80% {
    transform: translate3d(0, -70%, 0);
  }
  90% {
    transform: translate3d(0, -80%, 0);
  }
  100% {
    transform: translate3d(0, -90%, 0);
  }
  110% {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%, 10% {
    transform: translate3d(0, 0, 0);
  }
  20% {
    transform: translate3d(0, -10%, 0);
  }
  30% {
    transform: translate3d(0, -20%, 0);
  }
  40% {
    transform: translate3d(0, -30%, 0);
  }
  50% {
    transform: translate3d(0, -40%, 0);
  }
  60% {
    transform: translate3d(0, -50%, 0);
  }
  70% {
    transform: translate3d(0, -60%, 0);
  }
  80% {
    transform: translate3d(0, -70%, 0);
  }
  90% {
    transform: translate3d(0, -80%, 0);
  }
  100% {
    transform: translate3d(0, -90%, 0);
  }
  110% {
    transform: translate3d(0, -100%, 0);
  }
}

.blueRoll {
  color: #327E8A;
  color: var(--blue);
  background-color: rgb(41,41,41);
  background-color: var(--grey-dark);
}

.redRoll {
  color: #8B0124;
  color: var(--red);
  background-color: rgb(41,41,41);
  background-color: var(--grey-dark);
}

.yellowRoll {
  color: #EDC168;
  color: var(--yellow);
  background-color: rgb(41,41,41);
  background-color: var(--grey-dark);
}

.lightGrey {
  background-color: rgb(56,56,56);
  background-color: var(--grey-dark-light);
  border: none;
}


/* ---------------- */
/* - RIGHT COLUMN - */
/* ---------------- */

#recentPostsPage{
  /* border-top: 1px solid rgba(80, 80, 80, 0.3);
  border-bottom: 1px solid  rgba(80, 80, 80, 0.3); */
  /* border: solid  rgba(80, 80, 80, 0.3); */
  /* box-shadow: 0 0 1em rgba(80, 80, 80, 0.1); */
  width: 100vw;
  height: 68vh;
  margin: 10vh 0;
  border-radius: 25px;
}

#linkedInPosts {
  grid-area: interestsText;
  width: 100vw;
  color: white;
  /* padding: 1em 0 0 0; */
  /* background-color: rgba(60, 60, 60, .2); */
  /* border-top-left-radius: 25px; */
  /* border-top-right-radius: 25px; */
}
#linkedInPosts {
  margin-top: 5vh;
}

@-webkit-keyframes spin {
  from { -webkit-transform: rotateY(0); }
  to   { -webkit-transform: rotateY(-360deg); }
}
@keyframes spin {
  from  { transform: rotateY(0);}
  to    { transform: rotateY(-360deg);}
}

.spin:hover {
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

#recentPostsContainer {
  /* background-color: black; */
  color: white;
  border-top: rgba(255, 255, 255, 0.8);
  border-bottom: rgba(255, 255, 255, 0.8);
  font-family: 'Lucida Grande', Verdana, Arial;
  font-size: 12px;
  min-height: 70vh;
  max-height: 70vh;
  width: 100%;
  /* display: grid;
  grid-template-columns: .5fr 5fr 3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "controlBar spinner fullScreen"; */
  /* background-image: -webkit-gradient(radial,
                        50% 500, 1,
                        50% 500, 400,
                        from(rgba(255, 255, 255, 0.3)),
                        to(rgba(255, 255, 255, 0)));
 background-repeat: no-repeat; */
}

#container {
  /* grid-area: spinner; */
  width: 100%;
  height: 100%;
  margin: 0 0;
  /* position: absolute;
  z-index: -1; */
  -webkit-perspective: 800; /* For compatibility with iPhone 3.0, we leave off the units here */
          /* perspective: 800; */
  perspective-origin: 50% 225px;
}

#stage {
  width: 100%;
  height: 100%;
  transition: transform 2s;
  transform-style: preserve-3d;
}

#shape {
  position: relative;
  top: 100px;
  margin: 0 auto;
  height: 200px;
  width: 200px;
  transform-style: preserve-3d;
}

.plane {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  align-content: center;
  font-family: Times, serif;
  color: black;
  background-color: rgba(255, 255, 255, 0.6);
  transition: transform 2s, opacity 2s, scale 300ms ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.plane>img {
  width: 100%;
  height: 100%;
  align-self: center;
  margin: 0 10%;
  padding: 0 0;
  opacity: 1;
}

#backfaces {
  opacity: 0;
}

.controlsIcon {
  font-size: 3em;
  margin: 0 .05em;
  padding: .2em .3em;
  transition: all 150ms ease-in-out;
  color: #327E8A;
  color: var(--blue);
}
.controlsIcon>*:hover {
  cursor: pointer;
}

.controlsIcon>*:hover {
  text-shadow: 0 0 1em rgba(0, 0, 0, 1);
  border-radius: 100px;
  transform: scale(1.2);
  transition: all 150ms ease-in-out;
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}

i #cubeIcon {
  -webkit-animation: spin 2s infinite linear;
          animation: spin 2s infinite linear;
}


.visibleIcon>i {
  color: #327E8A;
  color: var(--blue);
}
.invisibleIcon>i {
  color: #65cddd;
  color: var(--blue-light);
}

#shape.backfaces .plane {
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
}
/* VARYING CUBE SPIN SPEEDS */
#shape.cube {
  -webkit-animation: spin 12s infinite linear;
          animation: spin 12s infinite linear;
}
#shape.cube.quickSpin {
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
#shape.cube.verySlow {
  -webkit-animation: spin 18s infinite linear;
          animation: spin 18s infinite linear;
}
/* VARYING RING SPIN SPEEDS */
#shape.ring {
  -webkit-animation: spin 25s infinite linear;
          animation: spin 25s infinite linear;
}
#shape.quickSpin {
  -webkit-animation: spin 5s infinite linear;
          animation: spin 5s infinite linear;
}
#shape.verySlow {
  -webkit-animation: spin 45s infinite linear;
          animation: spin 45s infinite linear;
}




#shape:hover, #shape:hover .plane{
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  /* cursor: pointer; */
  opacity: 1;
}

/* .plane:hover {
  transform: scale(1.5);
  transition: scale 300ms ease-in-out;
} */

#shape.pause {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
#tornado.controlsIcon.pause, #snail.controlsIcon.pause {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}



/* ---------- cube styles ------------- */
.cube > .one {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) rotateX(90deg) translateZ(100px);
}

.cube > .two {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) translateZ(100px);
}

.cube > .three {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) rotateY(90deg) translateZ(100px);
}

.cube > .four {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) rotateY(180deg) translateZ(100px);
}

.cube > .five {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) rotateY(-90deg) translateZ(100px);
}

.cube > .six {
  opacity: 0.5;
  transform: scale3d(1.2, 1.2, 1.2) rotateX(-90deg) translateZ(100px) rotate(180deg);
}


.cube > .seven {
  transform: scale3d(0.8, 0.8, 0.8) rotateX(90deg) translateZ(100px) rotate(180deg);
}

.cube > .eight {
  transform: scale3d(0.8, 0.8, 0.8) translateZ(100px);
}

.cube > .nine {
  transform: scale3d(0.8, 0.8, 0.8) rotateY(90deg) translateZ(100px);
}

.cube > .ten {
  transform: scale3d(0.8, 0.8, 0.8) rotateY(180deg) translateZ(100px);
}

.cube > .eleven {
  transform: scale3d(0.8, 0.8, 0.8) rotateY(-90deg) translateZ(100px);
}

.cube > .twelve {
  transform: scale3d(0.8, 0.8, 0.8) rotateX(-90deg) translateZ(100px);
}

/* ---------- ring styles ------------- */
.ring > .one {
  transform: translateZ(380px);
}

.ring > .two {
  transform: rotateY(30deg) translateZ(380px);
}

.ring > .three {
  transform: rotateY(60deg) translateZ(380px);
}

.ring > .four {
  transform: rotateY(90deg) translateZ(380px);
}

.ring > .five {
  transform: rotateY(120deg) translateZ(380px);
}

.ring > .six {
  transform: rotateY(150deg) translateZ(380px);
}

.ring > .seven {
  transform: rotateY(180deg) translateZ(380px);
}

.ring > .eight {
  transform: rotateY(210deg) translateZ(380px);
}

.ring > .nine {
  transform: rotateY(-120deg) translateZ(380px);
}

.ring > .ten {
  transform: rotateY(-90deg) translateZ(380px);
}

.ring > .eleven {
  transform: rotateY(300deg) translateZ(380px);
}

.ring > .twelve {
  transform: rotateY(330deg) translateZ(380px);
}

.controls {
  /* grid-area: controlBar; */
  width: 100%;
  margin: 0 0;
  text-align: center;
  /* padding: 0 0 1em 0; */
  /* -webkit-border-radius: 12px;
          border-radius: 12px; */
  /* background-color: rgba(60, 60, 60, .2); */
}






















/* --------------------------------------- */
/* ------------ CONTACT PAGE ------------- */
/* ----------- & REF todo.css ------------ */
/* --------------------------------------- */
/* @import "./ContactStyle/contactPage.css"; */




/* ———————————————————————————————————————————————————— */
/* IDS */
/* #ResourcesProjects {
}

.stillObject { */
/* } */
/*(query)*/
/* .animate {
} */
/* WALL TWO */
/* #wallTwo {
}

#NAUBA {
}

#artbox {
  z-index: 5;
}

#ipad {
  z-index: 5;
}

#paintset {
  z-index: 5;
}

#cantippedover {
  z-index: 5;
} */
/* WALL THREE */
/* #wallThree {
} */
/* WALLFOUR */
/* #wallFour {
}

#bookcase {
} */
/* COUNTER */
/* #counter {
}

.letter {
  position: relative;
  margin-bottom: 10px;
}

#letter-resume {
  position: absolute;
  z-index: 5;
}

#letter-gaCertificate {
  margin: 50px;
} */
/*# sourceMappingURL=style.css.map */
